<template>
    <div class="popup" :class="classObj">
        <van-popup v-model="show" @click-overlay="close">
            <div class="icon-close" @click="close">
                <i class="iconfont icon-s-cuowu-guanbi"></i>
            </div>
            <div class="content">
                <div class="header">
                    <div class="title">
                        <div class="img">
                            <img src="@/assets/deposit/newDeposit.png" />
                        </div>
                        <div>{{ $t("deposit.DepositBonus") }}</div>
                    </div>
                    <div class="desc">{{ $t("deposit.DepositTips") }}</div>
                </div>
                <div class="main-content">
                    <div class="btn" @click="close">{{ $t("deposit.Deposit") }}</div>
                    <div v-if="RechargeList && RechargeList.length" class="table">
                        <div>{{ $t('deposit.OnlineDeposit') }}</div>
                        <div class="table-header">
                            <div>{{ $t("deposit.DepositAmount") }}</div>
                            <div>{{ $t("deposit.Bonus") }}</div>
                        </div>
                        <div class="table-body">
                            <div class="tr" v-for="(item, index) in RechargeList" :key="index">
                                <div>{{ CurrencyType }} {{ item.rechargeAmt }}</div>
                                <div>
                                    <div class="extra"
                                        v-if="gratuityConditions == 'MAXIMUM' && (item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate))">
                                        <span v-if="userInfo && userInfo.firstRechargeState == 'No'">
                                            +{{ item.firstHandselRate > item.handselRate ? (item.firstHandselRate >
                                                item.everyHandselRate ? (item.firstHandselRate || 0).toFixed(2) :
                                                (item.everyHandselRate || 0).toFixed(2)) : (item.handselRate >
                                                    item.everyHandselRate ? (item.handselRate || 0).toFixed(2) :
                                                    (item.everyHandselRate || 0).toFixed(2)) }}
                                        </span>
                                        <span v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No'">
                                            +{{ item.handselRate > item.everyHandselRate ? (item.handselRate ||
                                                0).toFixed(2) : (item.everyHandselRate || 0).toFixed(2) }}
                                        </span>
                                        <span v-else>{{ (item.everyHandselRate || 0).toFixed(2) }}</span>
                                        <span>%</span>
                                    </div>
                                    <div class="extra"
                                        v-else-if="item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate)">
                                        <div v-if="userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate">
                                            +{{ item.handselRate + item.firstHandselRate + item.everyHandselRate }}%
                                        </div>
                                        <div
                                            v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate">
                                            +{{ item.handselRate + item.everyHandselRate }}%
                                        </div>
                                        <div v-else>+{{ (item.everyHandselRate || 0).toFixed(2) }}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="usdtList && usdtList.length" class="table">
                        <div>{{ $t('deposit.TransferDeposit') }}</div>
                        <div class="table-header">
                            <div>{{ $t("deposit.DepositAmount") }}</div>
                            <div>{{ $t("deposit.Bonus") }}</div>
                        </div>
                        <div class="table-body">
                            <div class="tr" v-for="(item, index) in usdtList" :key="index">
                                <div>U {{ item.rechargeAmt }}</div>
                                <div>
                                    <div class="extra"
                                        v-if="usdtGratuityConditions == 'MAXIMUM' && (item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate))">
                                        <span v-if="userInfo && userInfo.firstRechargeState == 'No'">
                                            +{{ item.firstHandselRate > item.handselRate ? (item.firstHandselRate >
                                                item.everyHandselRate ? (item.firstHandselRate || 0).toFixed(2) :
                                                (item.everyHandselRate || 0).toFixed(2)) : (item.handselRate >
                                                    item.everyHandselRate ? (item.handselRate || 0).toFixed(2) :
                                                    (item.everyHandselRate || 0).toFixed(2)) }}
                                        </span>
                                        <span v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No'">
                                            +{{ item.handselRate > item.everyHandselRate ? (item.handselRate ||
                                                0).toFixed(2) : (item.everyHandselRate || 0).toFixed(2) }}
                                        </span>
                                        <span v-else>{{ (item.everyHandselRate || 0).toFixed(2) }}</span>
                                        <span>%</span>
                                    </div>
                                    <div class="extra"
                                        v-else-if="item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate)">
                                        <div v-if="userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate">
                                            +{{ item.handselRate + item.firstHandselRate + item.everyHandselRate }}%
                                        </div>
                                        <div
                                            v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate">
                                            +{{ item.handselRate + item.everyHandselRate }}%
                                        </div>
                                        <div v-else>+{{ (item.everyHandselRate || 0).toFixed(2) }}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="how">
                        <div class="how-title">{{ $t("deposit.howTitle") }}</div>
                        <div class="how-step">{{ $t("deposit.Step") }} 1 - {{ $t("deposit.Deposit") }}</div>
                        <img src="@/assets/deposit/DepositNow.png" />
                        <div class="how-tips">{{ $t("deposit.howTips1") }}</div>
                        <div class="how-step">{{ $t("deposit.Step") }} 2 - {{ $t("deposit.Step2Title") }}</div>
                        <img src="@/assets/deposit/DepositBouns.png" />
                        <div class="how-tips">{{ $t("deposit.howTips2") }}</div>
                    </div>
                    <div class="btn" @click="close">{{ $t("deposit.Deposit") }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getUserInfo } from '@/api/user'
import { getRechargeList } from '@/api/deposit'
import { Toast } from 'vant'
export default {
    name: 'depositRulesPopup',
    data() {
        return {
            show: false,
            userInfo: {},
            RechargeList: [],
            gratuityConditions: '',
            usdtList: [],
            usdtGratuityConditions: ''
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.show = false
        },
        open() {
            getUserInfo().then(res1 => {
                if (res1.code) return
                this.userInfo = res1.result
                const grandRechargeNumber = Number(this.userInfo.grandRechargeNumber) + 1

                getRechargeList().then(res => {
                    if (res.code) {
                        Toast(res.message)
                        return
                    }
                    const D = res.result

                    this.RechargeList = []
                    const rechargeGiftType = D.rechargeGiftType
                    if (rechargeGiftType === 'firstRecharge') {
                        this.RechargeList = D.firstRechargeDetailVoList
                        this.gratuityConditions = D.gratuityConditions
                    }
                    if (rechargeGiftType === 'repeatRecharge') {
                        this.RechargeList = D.everyRepeatRechargeDetailVoList
                        if (D && D.repeatRechargeNumberDetailVoList.length) {
                            if (D.repeatRechargeNumberDetailVoList.some(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))) {
                                this.RechargeList = D.repeatRechargeNumberDetailVoList.filter(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))[0].repeatRechargeDetailVoList
                            }
                        }
                    }

                    this.usdtList = []
                    this.usdtRechargeGiftType = D.usdtRechargeGiftType
                    if (this.usdtRechargeGiftType === 'firstRecharge') {
                        this.usdtList = D.usdtFirstRechargeDetailVoList
                        this.usdtGratuityConditions = D.usdtGratuityConditions
                    }
                    if (this.usdtRechargeGiftType === 'repeatRecharge') {
                        this.usdtList = D.usdtEveryRepeatRechargeDetailVoList
                        if (D && D.usdtRepeatRechargeNumberDetailVoList.length) {
                            if (D.usdtRepeatRechargeNumberDetailVoList.some(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))) {
                                this.usdtList = D.usdtRepeatRechargeNumberDetailVoList.filter(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))[0].repeatRechargeDetailVoList
                            }
                        }
                    }
                })
            })
            this.show = true
            this.$nextTick(() => { })
        }
    }
}
</script>

<style scoped lang="scss">
.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .icon-close {
            width: 0.5rem;
            height: 0.5rem;
            background: rgba(0, 0, 0, .4);
            border-radius: 50px;
            font-weight: 700;
            box-sizing: border-box;
            position: absolute;
            top: 10px;
            right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            cursor: pointer;

            &>i {
                color: #fff;
                font-size: .22rem;
                vertical-align: middle;
            }
        }

        .content {
            border-radius: 12px;
            background-color: rgb(245 248 250);
            width: 500px;
            max-height: 80vh;
            height: 700px;
            box-sizing: border-box;
            position: relative;
            overflow: auto;
            padding-bottom: 30px;
        }

        .header {
            height: 224px;
            background: linear-gradient(90deg, #b3ddf2, #b2e5ae);
            width: 100%;
            border-radius: 12px 12px 0 0;
            overflow: hidden;

            .title {
                height: 110px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 24px;
                color: #000000e6;
                font-weight: bold;

                &>.img {
                    width: 110px;
                    height: 100px;
                    padding: 15px;
                    margin-right: 15px;

                    &>img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .desc {
                background-color: rgba(236, 240, 246, 0.6);
                border-radius: 10px;
                margin: 0 12px;
                // margin-top: 15px;
                height: 1rem;
                padding: 20px 2%;
                text-align: center;
                font-size: .18rem;
                color: #4d4d4d;
            }
        }

        .main-content {
            padding: 0.2rem .28rem;

            .btn {
                display: block;
                width: 90%;
                margin: 4% auto 7%;
                margin-top: -20px;
                line-height: 40px;
                font-size: 14px;
                font-weight: bold;
                color: #8f310f;
                text-align: center;
                text-decoration: none;
                background: linear-gradient(180deg, #fff7bc 0%, #ffe766 44.79%, #ffb932 53.65%, #ffcf54 94.9%, #ffebb9 99.58%);
                border-radius: 5px;
                box-sizing: border-box;
            }

            .table {
                width: 100%;
                border: .01rem solid #388e3c;
                margin-bottom: .2rem;

                .table-header {
                    background: #388e3c;
                    color: #ffd700;
                    text-align: center;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    &>div:first-child {
                        height: 100%;
                        width: 70%;
                        padding: 10px;
                    }

                    &>div:last-child {
                        height: 100%;
                        width: 30%;
                        padding: 10px;
                    }
                }

                .table-body {
                    width: 100%;

                    &>.tr {
                        display: flex;
                        align-items: center;
                        border-bottom: .01rem solid #388e3c;

                        &>div:first-child {
                            height: 100%;
                            width: 70%;
                            border-right: .01rem solid #388e3c;
                            padding: 10px;
                        }

                        &>div:last-child {
                            height: 100%;
                            width: 30%;
                            padding: 10px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }

            .how {
                padding-bottom: 38px;

                .how-title {
                    font-weight: bold;
                    padding: 5px 2%;
                    text-align: center;
                    box-sizing: border-box;
                    color: #4d4d4d;
                }

                .how-step {
                    font-weight: bold;
                    background: linear-gradient(180deg, #fff613 0%, #fe9800 50%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: center;
                    margin: 10% 0px 6% 0px;
                }

                &>img {
                    box-sizing: border-box;
                    height: auto;
                    max-width: 100%;
                }

                .how-tips {
                    font-weight: bold;
                    padding: 0px 2%;
                    text-align: center;
                    color: #4d4d4d;
                }
            }
        }

    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            &.van-popup--center {
                top: 16vh;
                bottom: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                // height: 100% !important;
            }
        }

        .content {
            width: 100% !important;
            height: 100% !important;
            max-height: 100%;
            border-radius: 12px 12px 0 0 !important;
        }
    }
}
</style>
