<template>
    <div>
        <van-popup v-model="show" :class="classObj">
            <div class="gamePages">
                <gameHeader v-if="!classObj.mobile" @onNavBack="onNavBack" @toHome="toHome"></gameHeader>
                <div v-if="show" class="main-content">
                    <iframe v-if="isUrl && gameUrl" id="myIframe1" :src="gameUrl" frameborder="0"
                        allowfullscreen="allowfullscreen" webkitallowfullscreen="true" mozallowfullscreen="true"
                        style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 999"></iframe>
                    <iframe v-else id="myIframe2" frameborder="0" allowfullscreen="allowfullscreen"
                        webkitallowfullscreen="true" mozallowfullscreen="true"
                        style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 999"></iframe>
                </div>
                <div v-if="classObj.mobile" id="gameOptObj" class="draggable" @touchstart="handleTouchStart" @touchmove="handleTouchMove"
                    @touchend="handleTouchEnd" :style="{ top: `${positionY}px`, left: `${positionX}px` }">
                    <i class="iconfont icon-settings1" @click.stop="openOpt"></i>
                </div>
                <div class="OptView" v-if="isShowOpt">
                    <div class="closeBar">
                        <i class="iconfont icon-s-cuowu-guanbi" @click.stop="isShowOpt = false"></i>
                    </div>
                    <div class="deposit" @click.stop="toDeposit">{{ $t("deposit.Deposit") }}</div>
                    <div class="back" @click.stop="close">return to hall</div>
                </div>
            </div>
        </van-popup>
        <gameOut ref="gameOut" @gameOut="close"></gameOut>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getGameUrl } from '@/api/game'
import { getUserInfo } from '@/api/user'
import gameHeader from './gameHeader.vue'
import gameOut from './gameOut.vue'
import { pageRote } from '@/utils'
export default {
    components: { gameHeader, gameOut },
    data() {
        return {
            show: false,
            isUrl: true,
            gameUrl: '',
            id: '',
            gameInfoId: '',
            gamePlatformType: 'PG',
            providerType: '',
            positionX: -21, // 初始 X 位置
            positionY: 100, // 初始 Y 位置
            startX: 0,
            startY: 0,
            isDragging: false,
            isShowOpt: false,
            appObj: null
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        }
    },
    props: {
        params: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    gameInfoId: '',
                    gamePlatformType: '',
                    providerType: ''
                }
            }
        }
    },
    mounted() {
        this.appObj = document.getElementById('app')
    },
    methods: {
        handleTouchStart(event) {
            this.isDragging = true
            const touch = event.touches[0]
            const isVerticalScreen = this.$store.state.app.isVerticalScreen
            this.startX = touch.clientX
            this.startY = touch.clientY
            const optObj = document.getElementById('gameOptObj')
            if (optObj.style.top === '100px' && optObj.style.left === '-21px') {
                this.positionX = -21
                this.positionY = 100
            }
            if (isVerticalScreen) {
                if (this.$store.state.app.isLandscape) {
                    this.startX = touch.clientY * -1
                    this.startY = touch.clientX
                }
            } else {
                if (!this.$store.state.app.isLandscape) {
                    this.startX = touch.clientY
                    this.startY = touch.clientX * -1
                }
            }
        },
        handleTouchMove(event) {
            if (!this.isDragging) return
            event.preventDefault() // 阻止默认行为，避免页面滚动
            const touch = event.touches[0]
            const isVerticalScreen = this.$store.state.app.isVerticalScreen
            let tempX = touch.clientX
            let tempY = touch.clientY
            if (isVerticalScreen) {
                if (this.$store.state.app.isLandscape) {
                    tempX = touch.clientY * -1
                    tempY = touch.clientX
                }
            } else {
                if (!this.$store.state.app.isLandscape) {
                    tempX = touch.clientY
                    tempY = touch.clientX * -1
                }
            }
            this.positionX += tempX - this.startX
            this.positionY += tempY - this.startY
            this.startX = tempX
            this.startY = tempY
        },
        handleTouchEnd() {
            const bodyWidth = this.appObj.clientWidth
            if (this.positionX > bodyWidth / 2) {
                this.positionX = bodyWidth - 29
            } else {
                this.positionX = -21
            }
            this.isDragging = false
        },
        toDeposit() {
            this.$store.dispatch('user/setIsShowDepositPopup', true)
        },
        openOpt() {
            this.isShowOpt = true
        },
        open() {
            this.show = true
            this.isUrl = true
            this.gameUrl = ''
            this.$nextTick(() => {
                this.gameInfoId = this.params.gameInfoId
                this.id = this.params.id
                this.gamePlatformType = this.params.gamePlatformType
                this.providerType = this.params.providerType
                this.getGameUrl()
            })
        },
        close() {
            this.gameInfoId = ''
            this.id = ''
            this.providerType = ''
            this.gameUrl = ''
            this.$store.dispatch('app/setVerticalScreen', false)
            pageRote(this.$store.state.app.isLandscape)
            this.gamePlatformType = ''
            getUserInfo()
            this.isShowOpt = false
            this.show = false
        },
        onNavBack() {
            console.log(6666)
            this.$refs.gameOut.open()
        },
        toHome() {
            this.close()
            if (this.$route.path.indexOf('/Home') !== -1) {
                window.location.reload()
            } else {
                this.onNavTo({ url: '/Home', replace: 1 })
            }
        },
        async getGameUrl() {
            const { result, code } = await getGameUrl({
                gameInfoId: this.gameInfoId,
                gameId: this.id,
                gamePlatformType: this.gamePlatformType,
                providerType: this.providerType
            })
            if (code === 0) {
                if (this.gamePlatformType === 'PG') {
                    this.$store.dispatch('app/setVerticalScreen', true)
                    pageRote(!this.$store.state.app.isLandscape, true)
                }
                if (result.indexOf('<!doctype html>') !== -1) {
                    this.nodes = result
                    this.isUrl = false

                    if (window.PGSoftInterface !== undefined) {
                        window.PGSoftInterface.loadGame(result)
                    } else {
                        const iframe = document.getElementById('myIframe2')
                        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
                        iframeDoc.open()
                        iframeDoc.write(result)
                    }
                } else {
                    this.gameUrl = result
                    this.isUrl = true
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/common/styles/viriables.scss';
.draggable {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 8px;
    border: #009b15 1px solid;
    border-radius: 25px;
    z-index: 999999;
    transform: translateZ(150px);
    opacity: 0.5;
    .icon-settings1 {
        font-size: 35px;
        color: #009b15;
    }
}
.OptView {
    background: $bg-popup-color;
    width: 5rem;
    height: 3rem;
    position: absolute;
    z-index: 999999;
    border-radius: 15px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(150px);
    .deposit {
        height: .7rem;
        border-radius: .25rem;
        background: #ff7300;
        color: #fff;
        line-height: .7rem;
        font-size: .36rem;
        font-weight: bold;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.15rem;
    }
    .back {
        height: .5rem;
        border-radius: .17rem;
        background: #449c00;
        color: #fff;
        line-height: .5rem;
        font-size: .28rem;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
    }
    .closeBar {
        height: 0.5rem;
        .icon-s-cuowu-guanbi {
            float: right;
            font-size: 0.37rem !important;
            color: #fff;
            margin-right: 0.1rem;
        }
    }
}

.icon-home {
    width: .58rem;
    height: .58rem;
    cursor: pointer;
}

.icon-recharge {
    width: .6rem;
    height: .6rem;
    cursor: pointer;
    margin-right: .1rem;
}

.van-popup {
    &.van-popup--center {
        top: 0;
        left: 0;
        transform: translate3d(0, 0, 0);
        width: 100%;
        height: 100%;
    }
    &.mobile {
        .main-content {
            flex: none;
            position: static;
            width: 100%;
            height: 100%;
            iframe {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

.gamePages {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #15161c;
    overflow: hidden;

    .main-content {
        flex: 1;
        position: relative;

        iframe {
            overflow: hidden;
            /* 隐藏滚动条 */
        }
    }

    .status_bar {
        height: 0;
        width: 100%;
    }

    .webview_back {
        user-select: none;
        position: fixed;
        top: .4rem;
        width: 1.68rem;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 99999;
        background: #efefef;
    }

    .back_box {
        height: .6rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        z-index: 99;

        .back_arrow {
            width: .4rem;
            height: .4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            image {
                width: .3rem;
                height: .3rem;
            }
        }

        .back_fav {
            width: .4rem;
            height: .4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            image {
                width: .24rem;
                height: .24rem;
            }
        }
    }
}
</style>
