import request from '@/common/http/request'

// 保存银行卡（巴西）
export function saveBankBrazil(data) {
    return request({
        url: '/memberBankBrazil/saveBank',
        method: 'post',
        data
    })
}
// 修改银行卡（巴西）
export function updateBankBrazil(data) {
    return request({
        url: '/memberBankBrazil/updateBank',
        method: 'post',
        data
    })
}
// 获取银行卡（巴西）
export function getBankBrazil(data) {
    return request({
        url: '/memberBankBrazil/getBank',
        method: 'post',
        data,
        isNoToastErr: true
    })
}
// 保存银行卡（巴基斯坦）
export function saveBankPakistan(data) {
    return request({
        url: '/memberBankPakistan/saveBank',
        method: 'post',
        data
    })
}
// 修改银行卡（巴基斯坦）
export function updateBankPakistan(data) {
    return request({
        url: '/memberBankPakistan/updateBank',
        method: 'post',
        data
    })
}
// 获取银行卡（巴基斯坦）
export function getBankPakistan(data) {
    return request({
        url: '/memberBankPakistan/getBank',
        method: 'post',
        data,
        isNoToastErr: true
    })
}
// 查询银行渠道名称列表（巴基斯坦）
export function getBankListPakistan(data) {
    return request({
        url: '/memberBankPakistan/getChannelNameList',
        method: 'post',
        data
    })
}
// 保存银行卡（尼日利亚）
export function saveBankNigeria(data) {
    return request({
        url: '/memberBankNigeria/saveBank',
        method: 'post',
        data
    })
}
// 修改银行卡（尼日利亚）
export function updateBankNigeria(data) {
    return request({
        url: '/memberBankNigeria/updateBank',
        method: 'post',
        data
    })
}
// 获取银行卡（尼日利亚）
export function getBankNigeria(data) {
    return request({
        url: '/memberBankNigeria/getBank',
        method: 'post',
        data,
        isNoToastErr: true
    })
}
// 查询银行渠道名称列表（尼日利亚）
export function getBankListNigeria(data) {
    return request({
        url: '/memberBankNigeria/getBankNumberList',
        method: 'post',
        data
    })
}
// 保存银行卡（俄罗斯）
export function saveBankRussia(data) {
    return request({
        url: '/memberBankRussia/saveBank',
        method: 'post',
        data
    })
}
// 修改银行卡（俄罗斯）
export function updateBankRussia(data) {
    return request({
        url: '/memberBankRussia/updateBank',
        method: 'post',
        data
    })
}
// 获取银行卡（俄罗斯）
export function getBankRussia(data) {
    return request({
        url: '/memberBankRussia/getBank',
        method: 'post',
        data,
        isNoToastErr: true
    })
}
// 查询银行渠道名称列表（俄罗斯）
export function getBankListRussia(data) {
    return request({
        url: '/memberBankRussia/getSbpBankNameList',
        method: 'post',
        data
    })
}
// 提交提现表单
export function createMemberWithdrawOrder(data) {
    return request({
        url: '/memberWithdrawOrder/create',
        method: 'post',
        data,
        isNoToastErr: false
    })
}
// 系统代付列表
export function getWithdrawlist(data) {
    return request({
        url: '/setRecharge/withdrawlist',
        method: 'post',
        data
    })
}
// 保存银行卡（美国）
export function saveBankUSA(data) {
    return request({
        url: '/memberBankUsa/saveBank',
        method: 'post',
        data,
        showLoading: 1
    })
}
// 修改银行卡（美国）
export function updateBankUSA(data) {
    return request({
        url: '/memberBankUsa/updateBank',
        method: 'post',
        data,
        showLoading: 1
    })
}
// 获取银行卡（美国）
export function getBankUSA(data) {
    return request({
        url: '/memberBankUsa/getBank',
        method: 'post',
        data,
        isNoToastErr: true,
        showLoading: 1
    })
}
