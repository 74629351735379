<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="header">
                <div>{{ title }}</div>
                <div class="close">
                    <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                </div>
            </div>
            <div class="content">
                <!-- <div class="subTitle">
                    Commission reward
                </div> -->
                <div class="rebate_info" v-if="type === 'gameBettingRewards'">
                    <div>
                        <div>{{ info.firstRecommenderBonusRate }}%</div>
                        <div> {{ $t("share.Tier") }} 1</div>
                    </div>
                    <div>
                        <div>{{ info.secondRecommenderBonusRate }}%</div>
                        <div>{{ $t("share.Tier") }} 2</div>
                    </div>
                    <div>
                        <div>{{ info.thirdRecommenderBonusRate }}%</div>
                        <div>{{ $t("share.Tier") }} 3</div>
                    </div>
                </div>
                <div class="rebate_info" v-if="type === 'rechargeRewards'">
                    <div>
                        <div>{{ info.firstRechargeRewardsRate }}%</div>
                        <div>{{ $t("share.Tier") }} 1</div>
                    </div>
                    <div>
                        <div>{{ info.secondRechargeRewardsRate }}%</div>
                        <div>{{ $t("share.Tier") }} 2</div>
                    </div>
                    <div>
                        <div>{{ info.thirdRechargeRewardsRate }}%</div>
                        <div>{{ $t("share.Tier") }} 3</div>
                    </div>
                </div>
                <div class="rule" v-html="info.rule"></div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { gameRewardsRebate, rechargeRewardsRebate } from '@/api/rebate'
export default {
    name: 'subPop',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        }
    },
    data() {
        return {
            info: {}
        }
    },
    created() { },
    methods: {
        open() {
            if (this.type === 'gameBettingRewards') {
                gameRewardsRebate().then((res) => {
                    this.info = res.result
                })
            } else if (this.type === 'rechargeRewards') {
                rechargeRewardsRebate().then((res) => {
                    this.info = res.result
                })
            }
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    text-align: left;
    position: relative;
    color: $font-sub-color;

    &.mobile {
        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
                // background: $bg-popup-color;
                display: flex;
                flex-direction: column;
                padding-bottom: 1rem;
            }

            .header {
                width: 100%;
                height: 1.12rem;
                line-height: 1.12rem;
                font-size: .32rem;
                padding: 0 0.3rem;
                color: #fff;
                background: transparent;
                position: static;
                transform: translateX(0);
                text-align: left;
                background: $bg-popup-navbar-color;
                display: flex;
                justify-content: space-between;

                .close {
                    position: static;
                    // color: $font-iconfont-color;
                }
            }

            .content {
                padding: .4rem .3rem;
                .rule {
                    height: 2.3rem;
                }
            }

            .subTitle {
                display: none;
            }

            .rebate_info {
                >div {
                    height: 1.08rem;
                    padding: 0 .24rem;
                    font-size: .24rem;
                    // background: $bg-box-color;
                    border-radius: .24rem;
                    margin-bottom: .2rem;
                }
            }
        }
    }

    .van-popup {
        width: 500px;
        height: 700px;
        overflow: visible;
        background: $bg-popup-color;
        border-radius: .24rem;

        .header {
            width: 390px;
            height: 56px;
            font-size: 20px;
            color: #fff;
            line-height: 56px;
            // background: $bg-popup-navbar-color;
            // background-image: url(@/assets/common/top.png);
            // background-size: 100% 100%;
            // background-repeat: no-repeat;
            // background-position: top left;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            font-weight: bold;
            text-align: center;

            .close {
                position: absolute;
                top: 8px;
                right: -35px;
                color: $font-iconfont-color;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.3s;

                i {
                    font-size: 20px;
                }

                &:hover {
                    color: #ffffff;
                    rotate: -180deg;
                }
            }
        }

        .content {
            padding: 76px 40px 40px;

            .subTitle {
                font-weight: bold;
                color: #ffffff;
                font-size: 14px;
                margin-bottom: 8px;
            }

            .rule {
                overflow-x: hidden; /* 防止内容溢出 */
                overflow-y: scroll;
                word-wrap: break-word; /* 强制子元素在边界处换行 */
                height: 4.5rem;
            }
            .rebate_info {
                >div {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    padding: 0 15px;
                    font-size: 14px;
                    background: $bg-menu-color;
                    border-radius: .24rem;
                    margin-bottom: 8px;

                    >div {
                        flex: 1;

                        &:first-child {
                            font-weight: bold;
                            text-align: left;
                            color: #ffffff;
                        }

                        &:last-child {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}
</style>
