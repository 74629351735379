import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
import { host } from './index'
import websocket from '@/utils/webSocket'
import { isPWA, getPlatform } from '@/utils'

// create an axios instance
const service = axios.create({
    baseURL: host, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 600000 // 网络请求超时时间(秒)
})
// 取消请求的数组
const cancelToken = []
// request interceptor
service.interceptors.request.use(
    config => {
        let LoadingNum = store.state.app.LoadingNum
        if (config.showLoading) {
            store.dispatch('app/setIsShowLoading', config.showLoading)
            store.dispatch('app/setLoadingNum', (LoadingNum += 1))
        }
        // if (config.showLoading) store.dispatch('app/setLoadingNum', (LoadingNum += 1))
        // 获取请求协议+路径
        const requestUrl = `${config.method} ${config.url}`
        // 查找取消请求标识中是否存在相同的requestUrl
        const cancelIndex = cancelToken.findIndex(item => {
            return item.url === requestUrl
        })
        // 存在就说明重复了
        if (cancelIndex > -1) {
            // 取消
            cancelToken[cancelIndex].cancel()
            // 删除该标识
            cancelToken.splice(cancelIndex, 1)
        };
        // 添加响应拦截中成功后删除该标识的判断条件
        config.requestUrl = requestUrl
        // 当前请求添加取消方法
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        config.cancelToken = source.token
        // 传入本地取消请求
        cancelToken.push({
            url: requestUrl,
            cancel: source.cancel
        })
        if (store.getters.token) {
            config.headers.Authorization = store.getters.token
        }
        config.headers.language = store.getters.language
        config.headers.memberInfoId = store.getters.userInfo?.id
        config.headers.salesPersonId = store.getters.userInfo?.salesPersonId
        const urlParams = new URLSearchParams(window.location.search)
        if (isPWA()) {
            config.headers['X-User-Equipment'] = 'PWA'
        } else if (getPlatform() === 'ios') {
            config.headers['X-User-Equipment'] = 'IOS'
        } else if (urlParams.get('platform') === 'android') {
            config.headers['X-User-Equipment'] = 'Android'
        } else {
            config.headers['X-User-Equipment'] = 'H5'
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)
// 响应拦截器删除取消标识中已完成的请求
const responseDeleteCancelToken = config => {
    const cancelIndex = cancelToken.findIndex(item => {
        return item.url === config.requestUrl
    })

    if (cancelIndex > -1) {
        cancelToken.splice(cancelIndex, 1)
    }
}
// response interceptor
service.interceptors.response.use(
    /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

    /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
    response => {
        const config = response?.config
        let LoadingNum = store.state.app.LoadingNum
        // if (config.showLoading) store.dispatch('app/setLoadingNum', (LoadingNum -= 1))
        store.dispatch('app/setLoadingNum', (LoadingNum -= 1))
        if (LoadingNum <= 0) {
            store.dispatch('app/setIsShowLoading', 0)
        }
        const res = response?.data
        responseDeleteCancelToken(config)
        // if the custom code is not 0, it is judged as an error.
        if (res.code) {
            if (res.code === 401) {
                websocket.close()
                store.commit('user/SET_TOKEN', '')
                store.dispatch('user/changeLoginType', 2)
                store.dispatch('user/setIsShowLoginPopup', true)
            }
            if (res.status === 401) {
                websocket.close()
                store.commit('user/SET_TOKEN', '')
                store.dispatch('user/changeLoginType', 2)
                store.dispatch('user/setIsShowLoginPopup', true)
            }
            if (res.code === -2001) {
                console.log('印度版本要跳email')
            }
            if (res.status === -2001) {
                console.log('印度版本要跳email')
            }
            if (res.code === -2002) {
                store.dispatch('user/setIsShowBankSetPopup', true)
            }
            if (res.status === -2002) {
                store.dispatch('user/setIsShowBankSetPopup', true)
            }
            if (!config.isNoToastErr) Toast(res?.message)
            // Promise.reject(new Error(res.message || 'Error'))
            return res === undefined ? {} : res
        } else {
            return res
        }
    },
    error => {
        console.log('err' + JSON.stringify(error.response)) // for debug
        const response = error.response
        const config = response?.config
        let LoadingNum = store.state.app.LoadingNum
        // if (config?.showLoading) store.dispatch('app/setLoadingNum', (LoadingNum -= 1))
        store.dispatch('app/setLoadingNum', (LoadingNum -= 1))
        if (LoadingNum <= 0) {
            store.dispatch('app/setIsShowLoading', 0)
        }
        // 响应拦截器删除取消标识中已完成的请求
        const data = response?.data
        // 请求已发出，但服务器响应的状态码不在 2xx 范围内
        if (response) {
            // 删除取消标识
            responseDeleteCancelToken(config)
        }
        // 取消的请求给错误对象添加一个标识
        if (axios.isCancel(error)) {
            error.selfCancel = true
        }
        if (response?.status === 401) {
            websocket.close()
            store.commit('user/SET_TOKEN', '')
            store.dispatch('user/changeLoginType', 2)
            store.dispatch('user/setIsShowLoginPopup', true)
        }
        if (response?.status === -2001) {
            // router.push('email')
            console.log('印度版本要跳email')
        }
        if (response?.status === -2002) {
            store.dispatch('user/setIsShowBankSetPopup', false)
        }
        if (!config?.isNoToastErr && data) Toast(data?.message)
        if (data) data.code = data.status || 0
        return data
    }
)

export default service
