import request from '@/common/http/request'
import store from '@/store'
import router from '@/router'

// 登录
export function login(data) {
    return request({
        url: '/member/login',
        method: 'post',
        data,
        showLoading: 1
    })
}

// 注册
export function register(data) {
    return request({
        url: '/member/register',
        method: 'post',
        data,
        showLoading: 1
    })
}

// 登录或注册
export function phoneLoginOrRegister(data) {
    return request({
        url: '/phoneLoginOrRegister',
        method: 'post',
        data
    })
}
export function phoneRegister(data) {
    return request({
        url: '/phone/register',
        method: 'post',
        data
    })
}
export function userRegister(data) {
    return request({
        url: '/userName/register',
        method: 'post',
        data,
        showLoading: 1
    })
}
// 手机验证码
export function getPhoneCode(data) {
    return request({
        url: '/phoneCode',
        method: 'post',
        data
    })
}
// 修改密码
export function resetPwd(data) {
    return request({
        url: '/member/resetUserNameAndPwd',
        method: 'post',
        data,
        showLoading: 1
    })
}
// 退出
export function logout(data) {
    return request({
        url: '/member/logout',
        method: 'post',
        data
    })
}

// 会员基本信息
export function userInfo(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/member/userInfo?timestamp=' + timestamp,
        method: 'post',
        data,
        isNoToastErr: true
    })
}

// 会员信息
export function getUserInfo(data) {
    return new Promise((resolve, reject) => {
        userInfo(data)
            .then((res) => {
                if (res && !res.code) {
                    const D = res.result
                    if (!D) return
                    store.dispatch('user/setMatterNumber', D.matterNumber)
                    store.dispatch('user/saveUserInfo', D)
                    if (D.enableState === 'disable') {
                        logout()
                        store.dispatch('resetToken')
                        router.push('/')
                    } else {
                        resolve(res)
                    }
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}
// 会员等级
export function getListLevel(data) {
    return request({
        url: '/member/listLevel',
        method: 'post',
        data,
        isNoToastErr: true,
        showLoading: 1
    })
}

// 判断是否登录
export function verifyToken(data) {
    return request({
        url: '/member/verifyToken',
        method: 'post',
        data,
        isNoToastErr: true,
        showLoading: 1
    })
}

// 奖金转余额计算流水限制
export function transferTurnoverLimit(data) {
    return request({
        url: '/memberAmtAccount/transferTurnoverLimit',
        method: 'post',
        data,
        showLoading: 2
    })
}

// 转游戏余额
export function doTransfer(data) {
    return request({
        url: '/memberAmtAccount/transfer',
        method: 'post',
        data,
        showLoading: 1
    })
}
