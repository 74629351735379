<template>
    <van-popup v-model="show" mode="center">
        <div class="content">
            <div class="title">
                {{ $t("logout.SignOut") }}
            </div>
            <div class="tip">
                {{ $t("logout.SignOutTips") }}
            </div>
            <div class="btns">
                <div class="btn" @click="handleLogout">
                    {{ $t("logout.SignOut") }}
                </div>
                <div class="btn" @click="close">
                    {{ $t("lang.cancel") }}
                </div>
            </div>
        </div>
    </van-popup>
</template>

<script>
import { logout } from '@/api/user'
export default {
    data() {
        return {
            show: false
        }
    },
    methods: {
        open() {
            this.show = true
        },
        close() {
            this.show = false
        },
        // 退出登录
        handleLogout() {
            logout().then((res) => {
                if (res.code) return
                this.$websocket.close()
                this.close()
                this.$store.commit('user/SET_TOKEN', '')
                this.$emit('logout')
                this.$store.dispatch('user/setIsShowMyProfilePopup', false)
                this.$store.dispatch('user/saveUserInfo', {})
                const urlParams = new URLSearchParams(window.location.search)
                if (urlParams.has('token')) {
                    if (this.$route.path.indexOf('/Home') !== -1) {
                        const opt = this.$route?.query
                        if (opt && opt.token) {
                            this.onNavTo({ url: '/Home', replace: 1 })
                        } else {
                            window.location.reload()
                        }
                    } else {
                        this.onNavTo({ url: '/Home', replace: 1 })
                    }
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';
.van-popup {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
}

.content {
    padding: .2rem .3rem .3rem;
    box-sizing: border-box;
    width: 6rem;
    background: $bg-popup-color;
    border-radius: .14rem;
    position: relative;
    color: #ffffff;
    font-size: .24rem;
    text-align: center;
    padding-top: .5rem;

    .title {
        font-size: .34rem;
        color: #fff;
        line-height: .7rem;
        // background-image: url(@/assets/common/top.png);
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        // background-position: top left;
        height: .7rem;
        width: 4.2rem;
        position: absolute;
        left: calc(50% - 2.1rem);
        top: 0;
    }

    .tip {
        margin: .3rem 0 .65rem;
        height: .36rem;
        line-height: .36rem;
    }

    .btns {
        display: flex;
        justify-content: space-between;
        column-gap: .3rem;
        height: .7rem;
        line-height: .66rem;

        .btn {
            flex: 1;
            padding: 0 .1rem;
            border-radius: .14rem;
            cursor: pointer;

            &:first-child {
                color: $font-theme-color;
                border: .01rem solid $font-theme-color;
            }

            &:last-child {
                color: $font-btn-color;
                background: $bg-btn-color;
            }
        }
    }
}
</style>
