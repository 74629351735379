<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <accountForm ref="accountForm" v-if="memberRegisterType == 'UserName'" @close="close" @loginSucc="$emit('loginSucc')"></accountForm>
            <phoneForm ref="phoneForm" v-if="memberRegisterType == 'Phone'" @close="close" @loginSucc="$emit('loginSucc')"></phoneForm>
        </van-popup>
    </div>
</template>

<script>
import accountForm from './accountForm.vue'
import phoneForm from './phoneForm.vue'
import { mapState } from 'vuex'
export default {
    name: 'loginPopup',
    components: { accountForm, phoneForm },
    data() {
        return {}
    },
    props: {
        isCanClose: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        show: {
            get() {
                return this.$store.getters.isShowLoginPopup
            },
            set(val) {
                return val
            }
        },
        memberRegisterType() {
            const memberRegisterType = this.$store.getters.sysConfig?.memberRegisterType
            return memberRegisterType || 'UserName'
        }
    },
    created() { },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowLoginPopup', false)
            if (!this.isCanClose) {
                this.onNavTo({ url: '/Home', replace: 1 })
            }
        },
        open() {
            this.$nextTick(() => {
                if (this.memberRegisterType === 'UserName') {
                    this.$refs.accountForm.open()
                } else if (this.memberRegisterType === 'Phone') {
                    this.$refs.phoneForm.open()
                }
            })
        }
    },
    beforeDestroy() {
        this.$store.dispatch('user/changeLoginType', 2)
        this.$store.dispatch('user/setIsShowLoginPopup', false)
    }
}
</script>

<style scoped lang="scss">
.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }
    ::v-deep .content{
        width: 100%;
        height: 6.5rem;
    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            height: 98% !important;
            &.van-popup--center {
                width: 100% !important;
                height: 100% !important;
            }
        }
        ::v-deep .content{
            width: 70%;
            height: 100% !important;
            overflow-y: scroll;
        }
        ::v-deep .banner_pc {
            display: none;
        }

        ::v-deep .LogReg {
            width: 100% !important;
            height: 87% !important;
        }
    }
}</style>
