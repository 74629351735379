<template>
    <div class="keyboard">
        <i class="iconfont icon-cuowu" @click.stop="closeModal"></i>
        <div class="ptoDiv" v-for="(keys, index) in keyList" :key="index">
            <div v-for="(key, indexs) in keys" :key="indexs">
                <i v-if="key === 'top'" @click.stop="clickKey"
                    class="iconfont icon-daxie tab-top"></i>
                <i v-else-if="key === '123'" @click.stop="clickKey" class="tab-num">123</i>
                <i v-else-if="key === 'del'" @click.stop="clickKey"
                    class="iconfont icon-shanchu key-delete"></i>
                <i v-else-if="key === 'blank'" @click.stop="clickKey"
                    class="iconfont icon-konggejian tab-blank"></i>
                <i v-else-if="key === 'symbol'" @click.stop="clickKey"
                    class="iconfont icon-fuhao tab-symbol"></i>
                <i v-else-if="key === 'point'" @click.stop="clickKey" class="tab-point">·</i>
                <i v-else-if="key === 'enter'" @click.stop="clickKey"
                    class="iconfont icon-huiche tab-enter"></i>
                <i v-else-if="key === 'abc'" @click.stop="clickKey"
                    class="toggle-letters">abc</i>
                <i v-else @click.stop="clickKey">{{ key }}</i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            keyList: [],
            status: 0,
            numberMode: false,
            lowercase: [
                ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
                ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
                ['top', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'del'],
                ['123', '@', 'point', 'blank', 'enter']
            ],
            uppercase: [
                ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
                ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
                ['top', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'del'],
                ['123', '@', 'point', 'blank', 'enter']
            ],
            num: [
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
                ['%', '!', '$', '~', '.', '(', ')'],
                ['abc', '=', '+', '-', '*', 'del']
            ]
        }
    },
    // props: {
    //     option: {
    //         type: Object
    //     }
    // },
    // computed: {
    //     showKeyboard() {
    //         return this.option.show
    //     }
    // },

    mounted() {
        this.keyList = this.lowercase
    },

    methods: {
        tabHandle(value) {
            if (value.includes('tab-num')) {
                this.status = 2
                this.keyList = this.num
                this.emitValue('setFouces')
            } else if (value.includes('key-delete')) {
                this.emitValue('delete')
            } else if (value.includes('tab-blank')) {
                this.emitValue(' ')
            } else if (value.includes('tab-enter')) {
                this.$emit('closed')
            } else if (value.includes('tab-point')) {
                this.emitValue('.')
            } else if (value.includes('tab-symbol')) {
                this.status = 3
            } else if (value.includes('tab-top')) {
                if (this.status === 0) {
                    this.status = 1
                    this.keyList = this.uppercase
                } else {
                    this.status = 0
                    this.keyList = this.lowercase
                }
                this.emitValue('setFouces')
            } else if (value.includes('toggle-letters')) {
                this.keyList = this.lowercase
                this.emitValue('setFouces')
            }
        },

        isNumericOrConvertible(str) {
            const num = Number(str)
            return !isNaN(num) && isFinite(num)
        },

        checkValueIsNumber(val) {
            if (this.numberMode) {
                if (val === 'delete') return true
                if (!this.isNumericOrConvertible(val) || val === ' ') {
                    this.$emit('keyVal', 'setFouces')
                    return false
                }
            }
            return true
        },

        setNumber(flag) {
            this.numberMode = flag
            if (flag) {
                this.tabHandle('tab-num')
            } else {
                this.tabHandle('toggle-letters')
            }
        },

        clickKey(event) {
            const value = event.target.innerText
            // 当按键不为下面几个时，调用的是tabHandle方法。判断类名
            if (value !== '' && value !== '符' && value !== '·' && value !== '123' && value !== 'abc') {
                this.emitValue(value)
            } else {
                this.tabHandle(event.target.classList.value)
            }
        },

        emitValue(key) {
            if (this.checkValueIsNumber(key)) {
                this.$emit('keyVal', key)
            }
        },

        closeModal() {
            this.$emit('closed')
        }
    }
}
</script>
<style scoped lang="scss">
.keyboard {
    width: 100%;
    margin: 0 auto;
    font-size: 0.3rem;
    border-radius: 0.1rem;
    padding-top: 0.5em;
    background-color: #e5e6e8;
    user-select: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    transform: translateZ(155px);
    pointer-events: auto;

    .icon-cuowu {
        position: absolute;
        bottom: 0.1rem;
        right: 0.2rem;
        font-size: 0.8rem;
    }

    .ptoDiv {
        width: 95%;
        margin: 0 auto;
        height: 0.6rem;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        i {
            display: block;
            height: 0.6rem;
            line-height: 0.6rem;
            font-style: normal;
            font-size: 24px;
            border-radius: 3px;
            width: 1rem;
            background-color: #fff;
            text-align: center;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            -webkit-box-flex: 1;
            margin-left: 0.2rem;

            &:active {
                background-color: darken(#ccc, 10%);
            }
        }

        .tab-top {
            width: 1.1rem;
            background: #cccdd0;
            color: #fff;
            font-size: 24px;
        }

        .key-delete {
            width: 1.1rem;
            color: #827f7f;
            background: #d7d7d8;
        }

        .tab-num {
            font-size: 0.35rem;
            background: #dedede;
            color: #5a5959;
        }

        .tab-point {
            width: 0.5rem;
        }

        .tab-blank {
            width: 3rem;
            font-size: 0.2rem;
            padding: 0 0.2px;
            line-height: 0.8rem;
        }

        .tab-symbol {
            width: 20px;
            font-size: 18px;
        }

        .tab-enter {
            font-size: 30px;
            line-height: 0.6rem;
        }

        &:nth-child(2) {
            width: 88%;
        }
    }
}

.keyboard .ptoDiv:nth-child(2) {
    width: 95%;
}
</style>
