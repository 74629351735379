import request from '@/common/http/request'

// 会员每日会员下注返佣信息
export function bettingRebateInfo(data) {
    return request({
        url: '/bettingRebateInfo/info',
        method: 'post',
        data
    })
}
// 获取会员每日会员下注返佣
export function receiveRebate(data) {
    return request({
        url: '/member/bettingRebateReceive',
        method: 'post',
        data
    })
}
// 下注返利活动获取活动信息
export function gameRewardsRebate(data) {
    return request({
        url: '/memberGameRewards/info',
        method: 'post',
        data
    })
}
// 代收返利活动获取活动信息
export function rechargeRewardsRebate(data) {
    return request({
        url: '/memberRechargeRewards/info',
        method: 'post',
        data
    })
}
