<template>
    <div class="page" v-show="show" @click="closePop" :class="classObj.mobile ? 'mobile' : 'pc'">
        <div class="unLogged_box" v-if="false">
            <div class="invite_benifits">
                <div>{{ $t("share.inviteFriend") }}</div>
                <div v-if="shareData.gameBettingRewardsEnableState === 'enable'">
                    {{ shareData.gameBettingRewardsRatio }} {{ $t("share.Commission") }}
                    <span>+</span>
                </div>
                <div v-if="shareData.rechargeRewardsEnableState === 'enable'">
                    {{ shareData.rechargeRewardsRatio }} {{ $t("share.DepositCashback") }}
                    <span>+</span>
                </div>
            </div>
            <div class="btn" @click="toLogin(2)">{{ $t("share.signUp") }}</div>
        </div>
        <div class="heardBar">
            <div class="main_title share_title">
                {{ $t("tabbar.Affiliate") }}
            </div>
            <i class="iconfont icon-s-cuowu-guanbi" @click="closes"></i>
        </div>
        <div class="container">

            <div v-if="isLogin">
                <div class="reverse_box shareBox">
                    <div class="invite_box main_box">
                        <div>
                            <div class="top_box">
                                <img src="@/assets/share/trumpet.png" alt="">
                                <div>
                                    <div>
                                        {{ $t("share.inviteFriend") }}
                                    </div>
                                    <div v-if="shareData.extendRewardsEnableState === 'enable'">
                                        {{ CurrencyType }} {{ shareData.extendRewardsAmt }}
                                        <span>+</span>
                                    </div>
                                    <div v-if="shareData.gameBettingRewardsEnableState === 'enable'">
                                        {{ shareData.gameBettingRewardsRatio }} {{ $t("share.Commission") }}
                                        <span>+</span>
                                    </div>
                                    <div v-if="shareData.rechargeRewardsEnableState === 'enable'">
                                        {{ shareData.rechargeRewardsRatio }} {{ $t("share.DepositCashback") }}
                                        <span>+</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="invite_title">
                                    {{ $t("share.InviteVia") }}
                                </div>
                                <div class="link_box main_box">
                                    <div class="qrcode-canvas">
                                        <!-- <img src="@/assets/share/facebook.png" alt=""> -->
                                        <canvas ref="qrcodeCanvas"></canvas>
                                    </div>
                                    <div class="link_info">
                                        <div class="my_link" v-if="!isHideShareLink" @click="copyText(inviteUrl)">
                                            <div>{{ $t("share.link") }}</div>
                                            <div class="info">{{ inviteUrl }}</div>
                                            <div class="btn">
                                                <span class="iconfont icon-fuzhiwenjian"></span>
                                            </div>
                                        </div>
                                        <div class="my_link" v-if="!isHideShareLink"
                                            @click="copyText(shareData.inviteCode)">
                                            <div>{{ $t("share.code") }}</div>
                                            <div class="info">{{ shareData.inviteCode }}</div>
                                            <div class="btn">
                                                <span class="iconfont icon-fuzhiwenjian"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="OR">
                                    {{ $t("share.or") }}
                                </div>
                                <div class="platforms">
                                    <div class="platform" v-for="(item, index) in platforms" :key="index">
                                        <img :src="item.icon" alt="" v-if="item.icon && item.url"
                                            @click="openOutLink(item.url)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data_box">
                        <div class="teamPc_box main_box">
                            <div class="box_item">
                                <div class="main_title">
                                    <span>{{ $t("share.TodayTeamData") }}</span>
                                    <!-- <i class="iconfont icon-changjianwentixiangguanwenti"
                                        @click.stop="showPop2 = true"></i> -->
                                </div>
                                <div class="content">
                                    <div class="item">
                                        <div>
                                            {{ $t("share.SignUp") }}
                                        </div>
                                        <div>
                                            {{ shareData.nowDayOneTeamNum }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div>
                                            {{ $t("share.FirstDeposit") }}
                                        </div>
                                        <div>
                                            {{ shareData.nowDayFirstRechargeNum }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box_item">
                                <div class="main_title">
                                    <span>{{ $t("share.TotalTeamData") }}</span>
                                    <!-- <i class="iconfont icon-changjianwentixiangguanwenti"
                                        @click.stop="showPop3 = true"></i> -->
                                </div>
                                <div class="content">
                                    <div class="item">
                                        <div>
                                            {{ $t("share.SignUp") }}
                                        </div>
                                        <div>
                                            {{ shareData.oneTeamNum }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div>
                                            {{ $t("share.FirstDeposit") }}
                                        </div>
                                        <div>
                                            {{ shareData.allFirstRechargeNum }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inner_box">
                            <div class="total_info">
                                <div>
                                    <div class="icon">
                                        <img src="@/assets/share/upgrade.png" alt="">
                                    </div>
                                    <div class="right_box">
                                        <div class="type">
                                            {{ $t("share.TotalRewards") }}
                                        </div>
                                        <div class="num">
                                            {{ CurrencyType }} {{ shareData.extendAllBonus || 0 }}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="icon">
                                        <img src="@/assets/share/friend.png" alt="">
                                    </div>
                                    <div class="right_box">
                                        <div class="type">
                                            {{ $t("share.TotalFriends") }}
                                        </div>
                                        <div class="num">
                                            {{ shareData.teamNum }}
                                        </div>
                                    </div>
                                    <i class="iconfont icon-icon" @click.stop="showPop1 = true"></i>
                                    <tipPop :show="showPop1">
                                        <template v-slot:content>
                                            <ul>
                                                <li>{{ $t("share.all") }}:{{ shareData.teamNum }}</li>
                                                <li>{{ $t("share.Direct") }}:{{ shareData.oneTeamNum }}</li>
                                                <li>{{ $t("share.Indirect") }}:{{ shareData.twoTeamNum }}</li>
                                            </ul>
                                        </template>
                                    </tipPop>
                                </div>
                            </div>
                            <div class="ungetCommission main_box">
                                <div class="top_box header">
                                    <div> {{ $t("share.UncollectedCommissions") }}</div>
                                    <div @click="showHistoryPop = true"> {{ $t("share.Details") }}</div>
                                </div>
                                <div class="content_box">
                                    <div class="detail_box">
                                        <div v-if="shareData.extendRewardsEnableState === 'enable'">
                                            <div class="title">
                                                {{ $t("share.ExtendRewardsBouns") }}
                                            </div>
                                            <div class="num">
                                                {{ CurrencyType }} {{ shareData.nowDayExtendRewardsBouns || 0 }}
                                            </div>
                                        </div>
                                        <div v-if="shareData.gameBettingRewardsEnableState === 'enable'">
                                            <div class="title">
                                                {{ $t("share.GameBettingRewardsBouns") }}
                                            </div>
                                            <div class="num">
                                                {{ CurrencyType }} {{ shareData.nowDayGameBettingRewardsBouns || 0 }}
                                            </div>
                                        </div>
                                        <div v-if="shareData.rechargeRewardsEnableState === 'enable'">
                                            <div class="title">
                                                {{ $t("share.RechargeRewardsBouns") }}
                                            </div>
                                            <div class="num">
                                                {{ CurrencyType }} {{ shareData.nowDayRechargeRewardsBouns || 0 }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="reward_box">
                <div class="main_title">
                    {{ $t("share.Rewards") }}
                </div>
                <div class="reward_info" v-if="shareData.extendRewardsEnableState === 'enable'">
                    <div>
                        <div class="info_title">
                            {{ shareData.extendRewardsTitle }}
                        </div>
                        <div class="info_msg">
                            {{ shareData.extendRewardsDescription }}
                        </div>
                        <div class="detail_btn" @click="goToActivity(shareData.extendRewardsEventId, 'extendRewards')">
                            {{ $t("share.Details") }}
                        </div>
                    </div>
                    <img src="@/assets/share/TreasureChest.png" alt="">
                </div>
                <div class="reward_info" v-if="shareData.gameBettingRewardsEnableState === 'enable'">
                    <div>
                        <div class="info_title">
                            {{ shareData.gameBettingRewardsTitle }}
                        </div>
                        <div class="info_msg">
                            {{ shareData.gameBettingRewardsDescription }}
                        </div>
                        <div class="detail_btn"
                            @click="goToActivity(shareData.gameBettingRewardsEventId, 'gameBettingRewards')">
                            {{ $t("share.Details") }}
                        </div>
                    </div>
                    <img src="@/assets/share/commission.png" alt="">
                </div>
                <div class="reward_info" v-if="shareData.rechargeRewardsEnableState === 'enable'">
                    <div>
                        <div class="info_title">
                            {{ shareData.rechargeRewardsTitle }}
                        </div>
                        <div class="info_msg">
                            {{ shareData.rechargeRewardsDescription }}
                        </div>
                        <div class="detail_btn"
                            @click="goToActivity(shareData.rechargeRewardsEventId, 'rechargeRewards')">
                            {{ $t("share.Details") }}
                        </div>
                    </div>
                    <img src="@/assets/share/recharge.png" alt="">
                </div>
            </div>
            <div class="ungetCommission main_box">
                <div class="top_box header">
                    <div> {{ $t("share.UncollectedCommissions") }}</div>
                    <div @click="showHistoryPop = true"> {{ $t("share.Details") }}</div>
                </div>
                <div class="content_box">
                    <div class="detail_box">
                        <div v-if="shareData.extendRewardsEnableState === 'enable'">
                            <div class="title">
                                {{ $t("share.ExtendRewardsBouns") }}
                            </div>
                            <div class="num">
                                {{ CurrencyType }} {{ shareData.nowDayExtendRewardsBouns || 0 }}
                            </div>
                        </div>
                        <div v-if="shareData.gameBettingRewardsEnableState === 'enable'">
                            <div class="title">
                                {{ $t("share.GameBettingRewardsBouns") }}
                            </div>
                            <div class="num">
                                {{ CurrencyType }} {{ shareData.nowDayGameBettingRewardsBouns || 0 }}
                            </div>
                        </div>
                        <div v-if="shareData.rechargeRewardsEnableState === 'enable'">
                            <div class="title">
                                {{ $t("share.RechargeRewardsBouns") }}
                            </div>
                            <div class="num">
                                {{ CurrencyType }} {{ shareData.nowDayRechargeRewardsBouns || 0 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="team_box main_box">
                <div class="header">
                    <div @click="changeTeamData(0)" :class="{ act: teamAct === 0 }">
                        <span>{{ $t("share.TodayTeamData") }}</span>
                        <i class="iconfont icon-changjianwentixiangguanwenti" @click.stop="showPop2 = true"></i>
                        <tipPop :show="showPop2">
                            <template v-slot:content>
                                <ul>
                                    <li>{{ $t("share.intro1") }}</li>
                                    <li>{{ $t("share.intro2") }}</li>
                                    <li>{{ $t("share.intro3") }}</li>
                                </ul>
                            </template>
                        </tipPop>
                    </div>
                    <div @click="changeTeamData(1)" :class="{ act: teamAct === 1 }">
                        <span>{{ $t("share.TotalTeamData") }}</span>
                        <i class="iconfont icon-changjianwentixiangguanwenti" @click.stop="showPop3 = true"></i>
                        <tipPop :show="showPop3">
                            <template v-slot:content>
                                <ul>
                                    <li>{{ $t("share.intro4") }}</li>
                                    <li>{{ $t("share.intro5") }}</li>
                                    <li>{{ $t("share.intro6") }}</li>
                                </ul>
                            </template>
                        </tipPop>
                    </div>
                </div>
                <div class="content" v-show="teamAct === 0">
                    <div class="item">
                        <div>
                            {{ $t("share.SignUp") }}
                        </div>
                        <div>
                            {{ shareData.nowDayOneTeamNum }}
                        </div>
                    </div>
                    <div class="item">
                        <div>
                            {{ $t("share.FirstDeposit") }}
                        </div>
                        <div>
                            {{ shareData.nowDayFirstRechargeNum }}
                        </div>
                    </div>
                </div>
                <div class="content" v-show="teamAct === 1">
                    <div class="item">
                        <div>
                            {{ $t("share.SignUp") }}
                        </div>
                        <div>
                            {{ shareData.oneTeamNum }}
                        </div>
                    </div>
                    <div class="item">
                        <div>
                            {{ $t("share.FirstDeposit") }}
                        </div>
                        <div>
                            {{ shareData.allFirstRechargeNum }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="outBox pc">
                <div class="reward_box">
                    <div class="main_title">
                        {{ $t("share.Rewards") }}
                    </div>
                    <div class="inset_box">
                        <div class="reward_info" v-if="shareData.extendRewardsEnableState === 'enable'">
                            <div>
                                <div class="info_title">
                                    {{ shareData.extendRewardsTitle }}
                                </div>
                                <div class="info_msg">
                                    {{ shareData.extendRewardsDescription }}
                                </div>
                                <div class="detail_btn"
                                    @click="goToActivity(shareData.extendRewardsEventId, 'extendRewards')">
                                    {{ $t("share.Details") }}
                                </div>
                            </div>
                            <img src="@/assets/share/TreasureChest.png" alt="">
                        </div>
                        <div class="reward_info" v-if="shareData.gameBettingRewardsEnableState === 'enable'">
                            <div>
                                <div class="info_title">
                                    {{ shareData.gameBettingRewardsTitle }}
                                </div>
                                <div class="info_msg">
                                    {{ shareData.gameBettingRewardsDescription }}
                                </div>
                                <div class="detail_btn"
                                    @click="goToActivity(shareData.gameBettingRewardsEventId, 'gameBettingRewards')">
                                    {{ $t("share.Details") }}
                                </div>
                            </div>
                            <img src="@/assets/share/commission.png" alt="">
                        </div>
                        <div class="reward_info" v-if="shareData.rechargeRewardsEnableState === 'enable'">
                            <div>
                                <div class="info_title">
                                    {{ shareData.rechargeRewardsTitle }}
                                </div>
                                <div class="info_msg">
                                    {{ shareData.rechargeRewardsDescription }}
                                </div>
                                <div class="detail_btn"
                                    @click="goToActivity(shareData.rechargeRewardsEventId, 'rechargeRewards')">
                                    {{ $t("share.Details") }}
                                </div>
                            </div>
                            <img src="@/assets/share/recharge.png" alt="">
                        </div>
                    </div>
                </div>
                <!-- <div class="live_box">
                    <div class="main_title">
                        {{ $t("share.LiveRewards") }}
                    </div>
                    <div class="main_box">
                        <div class="left_box">
                            <img src="@/assets/share/money_icon.png" alt="">
                            <div class="sendMoney">
                                <div>
                                    {{ $t("share.SendTotalCommission") }}
                                </div>
                                <div>
                                    ₦ 35,469,928,042
                                </div>
                            </div>
                        </div>
                        <div class="right_box" v-if="testData.length && !classObj.mobile">
                            <van-swipe style="height: 100%;" vertical :show-indicators="false" :autoplay="4000"
                                :duration="1000">
                                <van-swipe-item v-for="(group, index) in groupedMoneyList" :key="index">
                                    <div class="group" v-for="(item) in group" :key="item.id">
                                        <div class="phone">{{ item.phone }}</div>
                                        <div>
                                            <span class="txt">{{ $t("share.Commission") }}</span>
                                            <span class="money">₦ {{ item.count }}</span>
                                        </div>
                                    </div>
                                </van-swipe-item>
                            </van-swipe>
                        </div>
                    </div>
                </div> -->
                <div v-if="false" class="rule_box main_box">
                    <div class="header">
                        <div>{{ $t("share.rules") }}</div>
                        <!-- <i class="iconfont icon-changjianwentixiangguanwenti"
                            @click="goToActivity(shareData.gameBettingRewardsEventId, 'gameBettingRewards')"></i> -->
                    </div>
                    <div class="content">
                        <div>
                            <!-- <div class="tip">
                                {{ $t("share.ruleInfo") }}
                            </div> -->
                            <!-- <div class="invite_info">
                                <div class="left_box">
                                    <img src="@/assets/share/money_icon.png" alt="">
                                    <div>
                                        <div>
                                            <div>{{ $t("share.Recommended") }} </div>
                                            <div>>100 </div>
                                            <i class="iconfont icon-person"></i>
                                        </div>
                                        <div>
                                            <div>{{ $t("share.Commission") }} </div>
                                            <div>> ₦5,000 / {{ $t("share.Month") }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="right_box">
                                    <a-slider id="slider" :default-value="0" :tipFormatter="null" @change="onChange"
                                        @afterChange="onAfterChange" />
                                </div>
                            </div> -->
                            <div class="rules">
                                <ul>
                                    <li>
                                        {{ $t("share.rule1") }}
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="rules" v-html="rules"></div> -->
                        </div>
                        <div class="invite_example">
                            <div class="top">
                                <div>
                                    {{ $t("share.UnlimitedSubordinates") }}
                                </div>
                                <!-- <div>{{ $t("share.rules") }}</div> -->
                            </div>
                            <div class="img">
                                <img src="@/assets/share/proxy_bg.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="live_box">
                <div class="main_title">
                    {{ $t("share.LiveRewards") }}
                </div>
                <div class="main_box">
                    <div class="left_box">
                        <img src="@/assets/share/money_icon.png" alt="">
                        <div class="sendMoney">
                            <div>
                                {{ $t("share.SendTotalCommission") }}
                            </div>
                            <div>
                                ₦ 35,469,928,042
                            </div>
                        </div>
                    </div>
                    <div class="right_box" v-if="testData.length && classObj.mobile">
                        <van-swipe style="height: 100%;" vertical :show-indicators="false" :autoplay="4000"
                            :duration="1000">
                            <van-swipe-item v-for="(group, index) in groupedMoneyList" :key="index">
                                <div class="group" v-for="(item) in group" :key="item.id">
                                    <div class="phone">{{ item.phone }}</div>
                                    <div>
                                        <span class="txt">{{ $t("share.Commission") }}</span>
                                        <span class="money">₦ {{ item.count }}</span>
                                    </div>
                                </div>
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                </div>
            </div> -->
            <div class="rule_box main_box">
                <div class="header">
                    <div>{{ $t("share.rules") }}</div>
                    <!-- <i class="iconfont icon-changjianwentixiangguanwenti"
                        @click="goToActivity(shareData.gameBettingRewardsEventId, 'gameBettingRewards')"></i> -->
                </div>
                <div class="content">
                    <!-- <div class="tip">
                        {{ $t("share.ruleInfo") }}
                    </div> -->
                    <!-- <div class="invite_info">
                        <div class="left_box">
                            <img src="@/assets/share/money_icon.png" alt="">
                            <div>
                                <div>
                                    <div>{{ $t("share.Recommended") }} </div>
                                    <div>>100 </div>
                                    <i class="iconfont icon-person"></i>
                                </div>
                                <div>
                                    <div>{{ $t("share.Commission") }} </div>
                                    <div>> ₦5,000 / {{ $t("share.Month") }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="right_box">
                            <a-slider id="slider" :default-value="0" :tipFormatter="null" @change="onChange"
                                @afterChange="onAfterChange" />
                        </div>
                    </div> -->
                    <div class="invite_example">
                        <div class="top">
                            <div>
                                {{ $t("share.UnlimitedSubordinates") }}
                            </div>
                            <!-- <div>{{ $t("share.rules") }}</div> -->
                        </div>
                        <div class="img">
                            <img src="@/assets/share/proxy_bg.png" alt="">
                        </div>
                    </div>
                    <div class="rules">
                        <ul>
                            <li>
                                {{ $t("share.rule1") }}
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="rules" v-html="rules"></div> -->
                </div>
            </div>
            <!-- <div class="plan main_box">
                <div class="title">{{ $t("share.planTitle") }}</div>
                <div class="intro">
                    {{ $t("share.planInfo") }}
                </div>
            </div> -->
        </div>
        <!-- <loginPopup @loginSucc="refresh"></loginPopup> -->
        <historyPop :show="showHistoryPop" @close="showHistoryPop = false"></historyPop>
        <subPop :show="showSubPop" @close="showSubPop = false" :title="title" :type="type"> </subPop>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { Toast } from 'vant'
import QRCode from 'qrcode'
import tipPop from './modules/popup.vue'
import historyPop from './modules/history.vue'
import subPop from './modules/subPop.vue'
import {
    domain,
    inviteCodeKey
} from '@/common/http/index.js'
import handleCopy from '@/utils/copy.js'
import { getHideShareStatus, getShareinfo } from '@/api/share'
import { getSysBaseConfig } from '@/api/system'
export default {
    name: 'share',
    data() {
        return {
            teamAct: 0,
            isHideShareLink: false,
            shareData: {},
            inviteUrl: '',
            testData: [],
            rules: '',
            systemConfig: {},
            platforms: [],
            showPop1: false,
            showPop2: false,
            showPop3: false,
            showHistoryPop: false,
            eventID: null,
            showSubPop: false,
            title: '',
            type: ''
        }
    },
    components: {
        tipPop,
        historyPop,
        subPop
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        groupedMoneyList() {
            const groups = []
            for (let i = 0; i < this.testData.length; i += 2) {
                groups.push(this.testData.slice(i, i + 2))
            }
            return groups
        },
        show: {
            get() {
                if (this.$store.getters.isShowSharePopup) {
                    this.init()
                }
                return this.$store.getters.isShowSharePopup
            },
            set(val) {
                return val
            }
        }
    },
    // mounted() {
    //     this.init()
    // },
    methods: {
        goBack() {
            this.$router.back()
        },
        init() {
            this.getSysBaseConfig()
            this.generateRandomPhoneNumbers()
            if (this.isLogin) {
                this.getHideShareStatus()
                this.getShareInfo()
            }
        },
        closes() {
            // this.onNavTo({ url: '/Home', replace: 1 })
            // this.$emit('close')
            this.$store.dispatch('user/setIsShowSharePopup', false)
        },
        closePop() {
            this.showPop1 = false
            this.showPop2 = false
            this.showPop3 = false
        },
        formatPhone(phone) {
            return '****' + phone.slice(4, -4) + '****'
        },
        generateRandomPhoneNumber() {
            const prefix = Math.floor(1000 + Math.random() * 9000).toString()
            const middlePart = Math.floor(1000 + Math.random() * 9000).toString()
            const lastPart = Math.floor(1000 + Math.random() * 9000).toString()
            return `${prefix}${middlePart}${lastPart}`
        },
        generateRandomPhoneNumbers() {
            const generatedNumbers = []
            for (let i = 0; i < 30; i++) {
                const randomPhone = this.generateRandomPhoneNumber()
                generatedNumbers.push({
                    id: i,
                    phone: this.formatPhone(randomPhone),
                    count: Math.ceil(Math.random() * 9) * 5
                })
            }
            this.testData = generatedNumbers
        },
        changeTeamData(index) {
            if (index === this.teamActAct) {
                return
            }
            this.teamAct = index
        },
        onChange(value) {
            // console.log('change: ', value)
        },
        onAfterChange(value) {
            // console.log('afterChange: ', value)
        },
        copyText(content) {
            handleCopy(
                {
                    content,
                    success: (msg) => {
                        Toast(msg)
                    },
                    error: (msg) => {
                        Toast(msg)
                    }
                },
                this
            )
        },
        generateQRCode(link) {
            setTimeout(() => {
                if (this.isLogin) {
                    QRCode.toCanvas(this.$refs.qrcodeCanvas, link, error => {
                        if (error) console.error(error)
                    })
                }
            }, 1000)
        },
        getHideShareStatus() {
            getHideShareStatus({}, {
                custom: {
                    toast: false
                }
            }).then((res) => {
                if (res.code) return
                this.isHideShareLink = res.result
            })
        },
        getShareInfo() {
            getShareinfo().then((res) => {
                if (res.code) return
                const D = res.result
                this.shareData = D
                const userAgent = navigator.userAgent || navigator.vendor || window.opera
                if (/Windows/.test(userAgent)) {
                    // console.log('windows')
                    this.inviteUrl = window.location.origin + '?' + inviteCodeKey + '=' + D.inviteCode
                } else {
                    // console.log('app')
                    this.inviteUrl = domain + '?' + inviteCodeKey + '=' + D.inviteCode
                }
                this.$nextTick(() => {
                    this.generateQRCode(this.inviteUrl)
                })
            })
        },
        getSysBaseConfig() {
            getSysBaseConfig({}).then((res) => {
                if (res.code === 0) {
                    this.systemConfig = res.result
                }
            }).then(() => {
                this.GetPlatforms()
            })
        },
        GetPlatforms() {
            this.platforms = []
            const systemConfig = this.systemConfig
            const facebook = systemConfig.facebook?.trim()
            if (facebook) {
                const platform1 = {
                    id: 0,
                    icon: require('@/assets/share/icon_fackbook.png'),
                    title: 'Facebook',
                    url: facebook,
                    linkType: 'href'
                }
                this.platforms.push(platform1)
            }
            const telegram = systemConfig.telegram?.trim()
            if (telegram) {
                const platform2 = {
                    id: 1,
                    icon: require('@/assets/share/icon_telegram.png'),
                    title: 'Telegram',
                    url: telegram,
                    linkType: 'href'
                }
                this.platforms.push(platform2)
            }
            const instagram = systemConfig.instagram?.trim()
            if (instagram) {
                const platform3 = {
                    id: 2,
                    icon: require('@/assets/share/icon_ins.png'),
                    title: 'Instagram',
                    url: instagram,
                    linkType: 'href'
                }
                this.platforms.push(platform3)
            }
            const youTube = systemConfig.youTube?.trim()
            if (youTube) {
                const platform4 = {
                    id: 3,
                    icon: require('@/assets/share/icon_youtube.png'),
                    title: 'Youtube',
                    url: youTube,
                    linkType: 'href'
                }
                this.platforms.push(platform4)
            }
            const whatsapp = systemConfig.whatsapp?.trim()
            if (whatsapp) {
                const platform5 = {
                    id: 4,
                    icon: require('@/assets/share/icon_whatsapp.png'),
                    title: 'Whatsapp',
                    url: whatsapp,
                    linkType: 'href'
                }
                this.platforms.push(platform5)
            }
            const twitter = systemConfig.twitter?.trim()
            if (twitter) {
                const platform6 = {
                    id: 5,
                    icon: require('@/assets/share/icon_x.png'),
                    title: 'X',
                    url: twitter,
                    linkType: 'href'
                }
                this.platforms.push(platform6)
            }
            const tiktok = systemConfig.tiktok?.trim()
            if (tiktok) {
                const platform7 = {
                    id: 6,
                    icon: require('@/assets/share/icon_tiktok.png'),
                    title: 'TikTok',
                    url: tiktok,
                    linkType: 'href'
                }
                this.platforms.push(platform7)
            }
        },
        openOutLink(url) {
            window.open(url, '_blank')
        },
        toLogin(type) {
            this.$store.dispatch('user/changeLoginType', type)
            this.$store.dispatch('user/setIsShowLoginPopup', true)
        },
        // refresh() {
        //     if (this.$route.path.indexOf('/Affiliate') !== -1) {
        //         window.location.reload()
        //     } else {
        //         this.onNavTo({ url: '/Affiliate', replace: 1 })
        //     }
        // },
        goToActivity(id, type) {
            this.eventID = id
            this.type = type
            // this.onNavToByIsLogin({}).then(() => {
            //     this.$store.dispatch('user/setIsShowTreasureChestPopup', { id: this.eventID, show: true })
            // })
            if (type === 'extendRewards') {
                // 宝箱
                this.onNavToByIsLogin({}).then(() => {
                    this.$store.dispatch('user/setIsShowTreasureChestPopup', { id: this.eventID, show: true })
                })
            } else if (type === 'gameBettingRewards') {
                // 下注返利
                this.showSubPop = true
                this.title = this.$t('share.Commission')
            } else if (type === 'rechargeRewards') {
                // 代收返佣
                this.showSubPop = true
                this.title = this.$t('share.DepositCashback')
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: $bg-color;
    transform: translateZ(100px);

    .heardBar {
        width: 100%;
        height: 8vh;
        padding: 0 0.25rem;
        display: flex;
        justify-content: space-between; /* 添加这行 */
        align-items: center; /* 确保子元素垂直居中 */
        .main_title {
            font-size: .3rem;
            color: #ffffff;
            text-align: left;
            font-weight: bold;
        }
        .icon-s-cuowu-guanbi {
            font-size: 0.5rem !important;
            color: #fff;
        }
    }

    &.pc {
        .unLogged_box {
            margin-top: 40px;
            background-image: url(@/assets/share/noLogged_pc.png);
        }

        .header {
            background: transparent;
        }

        .container {

            .main_title {
                font-size: .45rem;
                color: #ffffff;
                text-align: left;
                font-weight: bold;
            }

            .team_box,
            .ungetCommission,
            .live_box,
            .rule_box,
            .reward_box {
                display: none;
            }

            .share_title {
                display: block;
            }

            .shareBox {
                // display: flex;
                flex-direction: column-reverse;
                row-gap: 20px;

                .data_box {
                    // display: flex;
                    gap: 20px;

                    >div {
                        flex: 1;
                    }

                    .teamPc_box {
                        display: flex;
                        flex-direction: column;
                        row-gap: .4rem;
                        margin-top: 0;
                        background: transparent;

                        .box_item {
                            flex: 1;
                            padding: 20px 5%;
                            background: $bg-box-color;
                            border-radius: .24rem;

                            .main_title {
                                i {
                                    margin-left: .2rem;
                                    font-size: .4rem;
                                }
                            }

                            .content {
                                display: flex;
                                column-gap: .2rem;
                                flex-wrap: wrap;
                                row-gap: .2rem;
                                padding: .3rem 0;

                                .item {
                                    width: 48%;
                                    border: 2px dashed $border-color;
                                    padding: .2rem;
                                    border-radius: .24rem;

                                    >div {
                                        &:first-child {
                                            font-size: .28rem;
                                            margin-bottom: .04rem;
                                            height: .34rem;
                                            line-height: .34rem;
                                            color: $font-label-color;
                                        }

                                        &:last-child {
                                            color: $font-sub-color;
                                            font-size: .32rem;
                                            height: .4rem;
                                            line-height: .4rem;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .inner_box {
                        background: $bg-box-color;
                        border-radius: .24rem;
                        padding: 20px 5%;
                        margin-top: 0.2rem;

                        .total_info {
                            display: flex;
                            flex-direction: column;
                            row-gap: .2rem;

                            >div {
                                padding: 0 .4rem;
                                column-gap: .4rem;
                                border: 2px dashed $border-color;
                                align-items: center;

                                .icon-icon {
                                    display: none;
                                }

                                .icon {
                                    width: 2rem;
                                    height: 2rem;

                                    >img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .right_box {

                                    .type {
                                        font-size: .3rem;
                                        height: 0.38rem;
                                    }

                                    .num {
                                        font-size: .6rem;
                                        margin-top: .2rem;
                                        text-align: left;
                                        height: .72rem;
                                    }
                                }
                            }
                        }

                        .ungetCommission {
                            display: block;
                            margin-top: 0;
                            overflow: hidden;

                            .top_box {
                                height: 1rem;
                                line-height: 1rem;
                                padding: 0;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                >div {
                                    font-size: .4rem;

                                    &:first-child {
                                        color: #ffffff;
                                        font-weight: 700;
                                    }

                                    &:last-child {
                                        font-size: .28rem;
                                    }
                                }
                            }

                            .content_box {
                                padding: 0;
                            }

                            .detail_box {
                                column-gap: .2rem;
                                text-align: center;
                                // border-bottom: .02rem solid #23262f;

                                >div {
                                    flex: 1;
                                    padding: 0.2rem;
                                    border: 2px dashed $border-color;
                                    border-radius: .24rem;
                                }
                            }

                            .bottom_box {
                                height: max-content;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                margin-top: .8rem;

                                .money {
                                    font-size: .6rem;
                                }
                            }
                        }
                    }
                }
            }

            .invite_box {
                margin-top: 0;
                padding: 32px 10%;
                background-position: center top;

                .platforms {
                    justify-content: center;
                    gap: 36px;
                }
            }

            .outBox {
                display: flex;
                flex-direction: column-reverse;

                .live_box,
                .rule_box,
                .reward_box {
                    display: block;
                }

                .rule_box {
                    background: transparent;
                    text-align: left;

                    .header {
                        height: .76rem;
                        display: flex;
                        align-items: center;
                        padding: 0;
                        column-gap: .2rem;

                        >div {
                            font-size: .4rem;
                        }

                        i {
                            font-size: .45rem;
                        }
                    }

                    .content {
                        border-radius: .24rem;
                        padding: .2rem .3rem;
                        background: $bg-box-color;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row-reverse;
                        align-items: center;
                        column-gap: 40px;

                        >div {
                            &:first-child {
                                flex: 1;
                            }
                        }
                    }

                    .tip {
                        font-size: .28rem;
                        line-height: .38rem;
                        margin-bottom: .3rem;
                    }

                    .invite_info {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-bottom: .3rem;

                        .left_box {
                            display: flex;
                            align-items: center;

                            img {
                                width: 1.5rem;
                                height: 1.5rem;
                                margin-right: .2rem;
                            }

                            >div {
                                >div {

                                    i {
                                        font-size: .5rem;
                                    }

                                    >div {
                                        height: .44rem;
                                        line-height: .44rem;
                                        font-size: .28rem;

                                        &:nth-child(2) {
                                            margin: 0 .08rem;
                                        }
                                    }
                                }
                            }
                        }

                        .right_box {
                            width: 4rem;
                        }
                    }

                    .rules {
                        color: $font-sub-color;
                        font-size: .24rem;
                        margin-top: 0;

                        ul {
                            padding: 0 .3rem;
                            list-style: decimal;

                            span {
                                color: #ffffff;
                            }
                        }
                    }

                    .invite_example {
                        .top {
                            padding: 0;

                            >div {
                                font-size: .24rem;
                            }
                        }

                        .img {
                            width: 342px;
                            margin-top: .1rem;
                        }
                    }
                }

                .live_box {
                    margin-top: 0.5rem;

                    .main_box {
                        height: 2.4rem;
                        display: flex;
                        padding: .2rem .4rem;

                        .left_box {
                            height: 100%;
                            flex: 1;
                            display: flex;
                            justify-content: left;
                            align-items: center;
                            border-right: .02rem solid #383e4b;

                            >img {
                                width: 1.7rem;
                                height: 1.7rem;
                            }

                            .sendMoney {
                                text-align: left;
                                margin-left: .2rem;

                                >div {
                                    &:first-child {
                                        font-size: .28rem;
                                    }

                                    &:last-child {
                                        font-size: .48rem;
                                    }
                                }
                            }
                        }

                        .right_box {
                            padding: 0 .5rem;
                            font-size: .28rem;

                            .group {
                                padding-top: .08rem;

                                .phone {
                                    color: #ffffff;
                                }

                                .txt {
                                    color: #97A8C7;
                                    margin-right: .06rem;
                                }

                                .money {
                                    color: #45D91e;
                                }
                            }

                        }
                    }
                }

                .reward_box {
                    margin-top: .5rem;

                    .inset_box {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        gap: .44rem;

                        >div {
                            flex: 1;
                        }
                    }

                    .reward_info {
                        margin-bottom: .2rem;
                        padding: .34rem .8rem 0;
                        border-radius: .24rem;
                        align-items: center;
                        justify-content: space-between;

                        >div {
                            padding: .14rem 0 .1rem;
                            flex: 1;
                            max-width: 50%;

                            .info_title {
                                height: max-content;
                                font-size: .4rem;
                                line-height: .4rem;
                                margin-bottom: .4rem;
                            }

                            .info_msg {
                                font-size: .32rem;
                                line-height: .4rem
                            }

                            .detail_btn {
                                width: 100%;
                                font-size: .28rem;
                            }
                        }

                        img {
                            width: 3.42rem;
                            height: 3.42rem;
                        }
                    }
                }
            }

            .plan {
                padding: .8rem;

                .title {
                    font-size: .6rem;
                    line-height: .6rem;
                }

                .intro {
                    margin-top: .36rem;
                    color: $font-sub-color;
                    font-size: .36rem;
                    line-height: .36rem;
                }
            }
        }
    }
}

.iconfont {
    font-weight: normal;

    &.icon-changjianwentixiangguanwenti {
        color: $font-iconfont-color;
    }

    &.icon-person {
        color: #97A8C7;
    }

    &.icon-icon {
        color: $font-iconfont-color;
        font-size: .24rem;
    }
}

.container {
    // margin: .2rem .3rem 0;
    padding: 0 0.25rem;
    height: 92vh;
    overflow-y: scroll;
    // .share_title {
    //     display: none;
    // }
    .main_title {
        font-size: .3rem;
        color: #ffffff;
        text-align: left;
        font-weight: bold;
    }
}

.outBox {
    display: none;
}

.reverse_box {
    // display: flex;
    flex-direction: column;

    .data_box {
        .teamPc_box {
            display: none;
        }

        .inner_box {
            .ungetCommission {
                display: none;
            }
        }
    }
}

.main_box {
    background: $bg-box-color;
    border-radius: .24rem;
}

.header {
    background: linear-gradient(90deg, #008705, #006b04);
}

.unLogged_box {
    background-image: url(@/assets/share/noLogged.png);
    background-size: auto 100%;
    background-position: top right;
    background-repeat: no-repeat;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .invite_benifits {
        width: 4.5rem;
        padding: .4rem .3rem;
        text-align: left;

        >div {
            font-weight: bold;

            &:first-child {
                color: #ffffff;
                font-size: .28rem;
            }

            font-size: .36rem;
            color: #F7D515;
            // filter: drop-shadow(1px 1px 1px #2a2f39) drop-shadow(-1px -1px 1px #2a2f39);
            // background: linear-gradient(90deg, #f3df00, #ff7300);
            // background-clip: text;
            // -webkit-background-clip: text;
            // color: transparent !important;
            margin-top: 0.04rem;

            &:last-child {
                span {
                    display: none;
                }
            }
        }
    }

    .btn {
        margin: 0 auto;
        border-radius: .24rem;
        width: 2.8rem;
        height: .8rem;
        line-height: .8rem;
        color: $font-btn-color;
        font-size: .22rem;
        background: $bg-btn-color;
        font-weight: bold;
        cursor: pointer;
    }
}

.total_info {
    display: flex;
    column-gap: .2rem;

    >div {
        flex: 1;
        background: $bg-box-color;
        display: flex;
        padding: .2rem;
        column-gap: 0.08rem;
        border-radius: .24rem;
        position: relative;

        .icon {
            width: .8rem;
            height: .8rem;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .right_box {
            font-weight: 700;

            .type {
                font-size: .2rem;
                color: $font-sub-color;
                height: 0.24rem;
                white-space: nowrap;
            }

            .num {
                font-size: .24rem;
                background: linear-gradient(90deg, #f3df00, #ff7300);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                margin-top: .12rem;
                text-align: left;
                height: .3rem;
            }
        }

        .icon-icon {
            position: absolute;
            right: .2rem;
            bottom: .2rem;
        }

        .pop {
            position: absolute;
            right: 0;
            bottom: -1.5rem;
            height: max-content;
        }
    }
}

.invite_box {
    margin-top: .2rem;
    margin-bottom: .2rem;
    padding: 0.3rem;
    background: $bg-box-color;
    // background-image: url(@/assets/share/share_bg.png);
    // background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;

    .top_box {
        display: flex;

        >img {
            width: 1.52rem;
            height: 1.44rem;
            margin-right: .2rem;
        }

        >div {
            >div {
                font-weight: 700;
                text-align: left;
                background: linear-gradient(90deg, #f3df00, #ff7300);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                font-size: .36rem;
                line-height: 0.42rem;

                &:first-child {
                    color: #ffffff;
                    font-size: .28rem;
                    font-weight: 400;
                    line-height: 0.34rem;
                }

                &:last-child {
                    span {
                        display: none;
                    }
                }
            }
        }
    }

    .invite_title {
        color: #ffffff;
        font-size: .28rem;
        margin: .2rem 0 .12rem;
        text-align: left;
        font-weight: 600;
    }

    .link_box {
        display: flex;
        align-items: center;
        column-gap: 0.12rem;

        .qrcode-canvas {
            background: #ffffff;
            width: 1.72rem;
            height: 1.72rem;
            overflow: hidden;
            position: relative;

            canvas {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 115% !important;
                height: 115% !important;
            }
        }

        .link_info {
            flex: 1;
            overflow: hidden;

            .my_link {
                display: flex;
                justify-content: space-between;
                font-size: .24rem;
                padding: 0.1rem 0.1rem 0.1rem .2rem;
                border: .02rem solid $border-input-color;
                border-radius: .24rem;
                // height: .8rem;
                line-height: .6rem;
                position: relative;
                background: $bg-input-color;
                line-height: .38rem;
                cursor: pointer;

                &:first-child {
                    margin-bottom: .12rem;
                }

                >div {
                    color: #ffffff;

                    &:first-child {
                        width: .72rem;
                        text-align: left;
                        color: $font-label-color;
                        word-break: break-all;
                        padding-right: .1rem;
                    }

                    &.info {
                        flex: 1;
                        text-align: left;
                        overflow: hidden;
                        padding-right: .1rem;
                        white-space: wrap;
                        flex-shrink: 0;
                        display: -webkit-box;
                        word-break: break-all;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &.btn {
                        background: $bg-btn-color;
                        padding: .19rem;
                        border-radius: .06rem;
                        font-weight: bold;
                        height: max-content;
                        color: $font-btn-color;
                    }
                }
            }
        }

    }

    .OR {
        color: $font-sub-color;
        height: .34rem;
        margin: .2rem 0 .12rem;
        font-size: .28rem;
        text-align: center;
        position: relative;

        &::before {}
    }

    .platforms {
        display: flex;
        justify-content: space-around;

        .platform {
            img {
                width: .8rem;
                height: .8rem;
            }
        }
    }
}

.reward_box {
    margin-top: .5rem;

    .reward_info {
        background: $bg-box-color;
        display: flex;
        justify-content: space-between;
        margin-bottom: .2rem;
        padding: 0.16rem .3rem .2rem;
        border-radius: .24rem;

        >div {
            padding: .14rem 0 .1rem;

            .info_title {
                font-size: .3rem;
                line-height: .3rem;
                min-height: .6rem;
                font-weight: bold;
                color: #ffffff;
                text-align: left;
            }

            .info_msg {
                text-align: left;
                color: #97a8c7;
                font-size: .24rem;

                span {
                    color: #ffffff;
                }
            }

            .detail_btn {
                width: 2.4rem;
                margin-top: .2rem;
                height: .8rem;
                line-height: .8rem;
                background: $bg-btn-color;
                color: $font-btn-color;
                padding: 0 .3rem;
                border-radius: .24rem;
                font-size: .24rem;
                font-weight: 700;
                text-align: center;
                cursor: pointer;
            }
        }

        img {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
}

.ungetCommission {
    margin-top: .5rem;
    overflow: hidden;

    .top_box {
        height: .76rem;
        padding: 0 .3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >div {
            font-size: .24rem;

            &:first-child {
                color: #ffffff;
                font-weight: 700;
            }

            &:last-child {
                color: $font-label-color;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .content_box {
        padding: .2rem .2rem .2rem .3rem;
    }

    .detail_box {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        border-bottom: .02rem solid #23262f;

        >div {
            width: 50%;
            padding: .4rem 0 0.2rem;

            .title {
                font-size: .2rem;
                color: $font-label-color;
            }

            .num {
                font-size: .24rem;
                color: #ffffff;
            }
        }
    }

    .bottom_box {
        color: #516382;
        height: .8rem;
        line-height: .8rem;
        display: flex;
        justify-content: space-between;
        margin-top: .2rem;

        .money {
            color: #ffffff;
            font-size: .36rem;
        }

        .btn {
            color: $font-btn-color;
            font-size: .28rem;
            background: $bg-join-btn;
            border-radius: .24rem;
            padding: 0 .3rem;
            min-width: 2.4rem;
            text-align: center;

            &.disable {
                opacity: 0.4;
            }
        }
    }
}

.team_box {
    text-align: center;
    margin-top: 0.5rem;
    border-radius: .24rem;

    .header {
        display: flex;
        align-items: center;
        height: 0.92rem;
        line-height: 0.92rem;

        >div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            font-weight: 700;
            padding: 0 .08rem;
            color: #EABBBD;
            font-size: .24rem;
            line-height: .3rem;
            position: relative;
            // white-space: nowrap;

            .iconfont {
                margin-left: .3rem;
                color: $font-iconfont-color;
            }

            &.act {
                color: #ffffff;
                background: $bg-switch-act-color;
                border-bottom: .02rem solid $font-theme-color;

                i {
                    color: #F7D515;
                }
            }

            .pop {
                position: absolute;
                right: 0;
                bottom: -1.5rem;
                width: 45.12vw;
                height: max-content;
                padding: .2rem;

                ul {
                    padding-left: .4rem;

                    li {
                        line-height: .24rem;
                        font-size: .2rem;
                        font-weight: normal;
                        white-space: wrap;
                    }
                }
            }
        }
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        row-gap: .2rem;
        padding: .3rem 0;

        .item {
            width: 50%;

            >div {
                &:first-child {
                    color: $font-label-color;
                    font-size: .2rem;
                    margin-bottom: .04rem;
                    height: .24rem;
                    line-height: .24rem;
                }

                &:last-child {
                    color: $font-sub-color;
                    font-size: .24rem;
                    height: .3rem;
                    line-height: .3rem;
                    font-weight: 700;
                }
            }
        }
    }
}

.live_box {
    margin-top: 0.5rem;

    .main_box {
        height: 1.64rem;
        display: flex;
        padding: .2rem;

        .left_box {
            height: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: .02rem solid #383e4b;

            >img {
                width: .66rem;
                height: .66rem;
            }

            .sendMoney {
                >div {
                    &:first-child {
                        font-size: .2rem;
                        color: #97a8c7;
                    }

                    &:last-child {
                        background: linear-gradient(90deg, #f3df00, #ff7300);
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                        font-size: .24rem;
                        font-weight: bold;
                    }
                }
            }
        }

        .right_box {
            height: 100%;
            padding: 0 .2rem;
            flex: 1;
            font-size: .2rem;
            text-align: left;

            .group {
                padding-top: .02rem;

                .phone {
                    color: #ffffff;
                }

                .txt {
                    color: #97A8C7;
                    margin-right: .06rem;
                }

                .money {
                    color: #45D91e;
                }
            }

        }
    }
}

.rule_box {
    margin-top: 0.5rem;
    overflow: hidden;
    border-radius: .24rem;
    height: 12rem;

    .header {
        height: .76rem;
        display: flex;
        align-items: center;
        padding: 0 .3rem;
        column-gap: .2rem;

        >div {
            color: #ffffff;
            font-weight: bold;
            font-size: .3rem;
        }

        i {
            cursor: pointer;
        }
    }

    .content {
        padding: .2rem .3rem;
    }

    .tip {
        font-size: .24rem;
        background: linear-gradient(90deg, #f3df00, #ff7300);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-weight: bold;
        line-height: .3rem;
        margin-bottom: .3rem;
    }

    .invite_info {
        display: flex;
        justify-content: space-between;
        margin-bottom: .3rem;

        .left_box {
            display: flex;
            align-items: center;

            img {
                width: .66rem;
                height: .66rem;
                margin-right: .2rem;
            }

            >div {
                >div {
                    display: flex;
                    align-items: center;

                    >div {
                        height: .3rem;
                        line-height: .3rem;
                        font-size: .24rem;

                        &:first-child {
                            color: #97A8C7;
                        }

                        &:nth-child(2) {
                            color: #ffffff;
                            margin: 0 .08rem;
                        }
                    }
                }
            }
        }

        .right_box {
            width: 1.2rem;

            ::v-deep .ant-slider-rail {
                background: #2a2f39;
            }

            ::v-deep .ant-slider-track {
                background: #2a2f39;
            }

            ::v-deep .ant-slider-handle {
                background: #516382;
                border: .04rem solid #516382;
            }
        }
    }

    .invite_example {
        .top {
            display: flex;
            justify-content: space-between;
            padding: 0.14rem .2rem 0;
            position: relative;
            z-index: 1;

            >div {
                font-size: .2rem;

                &:first-child {
                    color: $font-sub-color;
                    flex-wrap: bold;
                }

                // &:last-child {
                //     color: #afc2e4;
                //     text-decoration: underline;
                // }
            }
        }

        .img {
            width: 100%;
            border-radius: .24rem;
            margin-top: -0.42rem;
            background: url(@/assets/share/proxy.png) no-repeat;
            background-position: center;
            background-size: 100%;

            >img {
                width: 100%;
                opacity: 0.1;
            }
        }
    }

    .rules {
        font-size: .2rem;
        color: $font-sub-color;
        font-size: .2rem;
        margin-top: .2rem;
        text-align: left;

        ul {
            padding: 0 .3rem;
            list-style: decimal;

            span {
                color: #ffffff;
            }
        }
    }
}

.plan {
    padding: .3rem;
    text-align: left;
    margin-top: .5rem;

    .title {
        color: #ffffff;
        font-size: .3rem;
        font-weight: bold;
        line-height: .38rem;
    }

    .intro {
        margin-top: .2rem;
        color: #97A8C7;
        font-size: .24rem;
        line-height: .3rem;
    }
}

@media screen and (min-width: 1467px) {
    .shareBox {
        // display: flex;
        flex-direction: row !important;
        gap: .4rem !important;

        .data_box {
            flex: 2 !important;
        }

        .invite_box {
            flex: 1 !important;
            padding: 32px 0 !important;

            >div {
                height: 100%;
                padding: 0 10%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .OR {
                    margin: 0.7rem 0;
                }

                .platforms {
                    display: flex;
                    justify-content: center !important;
                    column-gap: .15rem !important;
                }
            }
        }
    }
}

//弹窗
.page {
    &.mobile {
        @media (orientation: landscape) {
            height: 100vh !important;
        }
        @media (orientation: portrait) {
            height: 100vw !important;
            .heardBar {
                height: 8vw !important;
            }
            .container {
                height: 92vw !important;
            }
        }
        .commisionPop_content {
            padding: .4rem .3rem;

            .subTitle {
                display: none;
            }

            .tip {
                p {
                    font-size: .24rem;
                    line-height: .3rem;
                }
            }

            .rebate_info {
                >div {
                    height: 1.08rem;
                    padding: 0 .24rem;
                    font-size: .24rem;
                    background: $bg-box-color;
                    border-radius: .24rem;
                    margin-bottom: .2rem;
                }
            }
        }

        .cashBack_content {
            padding: .4rem .3rem;

            .rebate_info {
                >div {
                    height: 1.08rem;
                    padding: 0 .24rem;
                    font-size: .24rem;
                    background: $bg-box-color;
                    border-radius: .24rem;
                    margin-bottom: .2rem;

                    >div {
                        line-height: .3rem;
                    }
                }
            }
        }
    }

    .commisionPop_content {
        padding: 76px 40px 40px;

        .subTitle {
            font-weight: bold;
            color: #ffffff;
            font-size: 14px;
            margin-bottom: 8px;
        }

        .tip {
            p {
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 0;
            }
        }

        .game_type {
            font-size: .36rem;
            color: #ffffff;
            font-weight: bold;
            margin: .2rem 0;
        }

        .rebate_info {
            >div {
                height: 50px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                font-size: 14px;
                background: #23262f;
                border-radius: .24rem;
                margin-bottom: 8px;

                >div {
                    flex: 1;

                    &:first-child {
                        font-weight: bold;
                        text-align: left;
                        color: #ffffff;
                    }

                    &:nth-child(2) {
                        text-align: center;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    .cashBack_content {
        padding: 76px 40px 40px;

        .rebate_info {
            >div {
                height: 50px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                font-size: 14px;
                background: #23262f;
                border-radius: .24rem;
                margin-bottom: 8px;

                >div {
                    line-height: 17px;
                    flex: 1;

                    &:first-child {
                        font-weight: bold;
                        text-align: left;
                        color: #ffffff;
                    }

                    &:nth-child(2) {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}
</style>
