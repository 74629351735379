<template>
    <div id="app">
        <router-view />
        <depositPopup></depositPopup>
        <withdrawPopup></withdrawPopup>
        <bankSet></bankSet>
        <loadingPopup></loadingPopup>
    </div>
</template>
<script>
import { Toast } from 'vant'
import { getSysBaseConfig } from '@/api/system'
import { getUserInfo } from '@/api/user'
import { domain } from '@/common/http'
import { getPWAValue } from '@/api/pwa'
import { isPWA, getPlatform, pageRote } from '@/utils'
export default {
    data() {
        return {
            mediaQuery: null
        }
    },
    created() {
        this.$i18n.locale = this.$store.getters.language
    },
    mounted() {
        const that = this
        // 设置屏幕翻转
        const bodyWidth = window.innerWidth
        const bodyHeight = window.innerHeight
        const currentUrl = window.location.href
        const isDownAppPWA = currentUrl.includes('downAppPWA')
        const isPhoneDec = /iPhone|iPad|iPod|Android|mobile/i.test(navigator.userAgent)
        Toast.setDefaultOptions({
            getContainer: '#app' // 指定挂载到 #app
        })
        that.$store.dispatch('app/setViewWidth', bodyWidth)
        that.$store.dispatch('app/setViewHeight', bodyHeight)
        if (bodyWidth > bodyHeight || !isPhoneDec) {
            that.$store.dispatch('app/setLandscape', true)
        }
        if (isPhoneDec && bodyWidth < bodyHeight && !isDownAppPWA) {
            pageRote(false)
        }
        // 如果是PWA的话横屏进入要延迟渲染一下
        // if (isPWA()) {
        //     setTimeout(() => {
        //         if (this.$store.state.app.isLandscape) {
        //             pageRote(true)
        //         }
        //     }, 1000)
        // }
        if (this.$store.getters.invitCode || isPWA()) this.getPWAValue()
        getSysBaseConfig().then((res) => {
            if (res.code) return
            const D = res.result
            this.$store.dispatch('system/setSysConfig', D)
            if (D.belongCountry && (!this.$store.state.system.userSetlanguage)) {
                this.$store.dispatch('system/setLanguage', D.belongCountry)
                this.$i18n.locale = D.belongCountry
            }
            try {
                const link = document.querySelector("link[rel*='icon']")
                if (link) link.href = D.homeImageUrl
                document.querySelector('meta[property="og:title"]').setAttribute('content', D.projectName)
                document.querySelector('meta[property="og:url"]').setAttribute('content', domain)
                document.querySelector('meta[property="og:image"]').setAttribute('content', D.homeLogoUrl)
                document.querySelector('meta[property="og:description"]').setAttribute('content', D.projectName)
            } catch (e) {
                console.log(e)
            }
        })
        try {
            const url = new URL(window.location.href)
            const searchParams = new URLSearchParams(url.search)
            let token = this.$store.getters.token
            if (searchParams.has('token')) {
                const newToken = searchParams.get('token')
                if (newToken !== token) {
                    token = newToken
                    this.$store.commit('user/SET_TOKEN', token)
                }
            }
            if (token) {
                getUserInfo().then((uInfo) => {
                    if (uInfo.code) return
                    that.$websocket.initWebSocket()
                })
            } else {
                this.$websocket.close()
            }
        } catch (e) {
            console.log(e)
        }
        try {
            let isInstallPWA = 2
            if ('serviceWorker' in navigator) {
                window.addEventListener('beforeinstallprompt', function (e) {
                    // 阻止默认提示弹出
                    e.preventDefault()
                    that.$store.dispatch('system/setIsShowDownAppNav', 1)
                    that.$store.dispatch('system/setIsSaveVersionDesktop', 1)
                    isInstallPWA = 1
                    // console.log('PWA未安装555555')
                })
                if (isInstallPWA === 2) {
                    // console.log('PWA已经安装666666')
                    if (getPlatform() === 'ios') return
                    that.$store.dispatch('system/setIsShowDownAppNav', 2)
                    that.$store.dispatch('system/setIsSaveVersionDesktop', 2)
                }
            } else {
                // console.log('Service Worker 不受此浏览器支持')
                // that.$store.dispatch('system/setIsShowDownAppNav', 1)
                that.$store.dispatch('system/setIsSaveVersionDesktop', 2)
            }
        } catch (e) {
            console.log(e, 'PWAfail')
        }
        window.addEventListener('beforeunload', this.handleBeforeUnload)
        this.mediaQuery = window.matchMedia('(orientation: landscape)')
        this.mediaQuery.addEventListener('change', this.handleOrientationChange)
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload)
        this.mediaQuery.removeEventListener('change', this.handleOrientationChange)
    },
    methods: {
        handleOrientationChange(event) {
            const isVerticalScreen = this.$store.state.app.isVerticalScreen
            this.$store.dispatch('app/setLandscape', event.matches)
            let boolLandscape = event.matches
            let flag = false
            if (isVerticalScreen) {
                boolLandscape = !boolLandscape
                flag = true
            }
            const gameOptObj = document.getElementById('gameOptObj')
            if (gameOptObj !== null) {
                gameOptObj.style.top = '100px'
                gameOptObj.style.left = '-21px'
            }
            pageRote(boolLandscape, flag)

            setTimeout(() => {
                pageRote(boolLandscape, flag)
            }, 150)
        },
        handleBeforeUnload(event) {
            this.$websocket.close()
            // console.log('close', event)
        },
        getPWAValue() {
            const that = this
            const userAgent = navigator.userAgent
            const userAg = userAgent.replace(/[\/()\.,;\s]/g, '')
            const params = { userAg }
            getPWAValue(params).then((res) => {
                if (res.code) return
                const D = res.result
                that.$store.dispatch('user/setInvitCode', D.invitationCode)
                that.$store.dispatch('system/setPixel', D.trafficAcquisitionType)
                that.$store.dispatch('system/setTaId', D.taid)
                that.$store.dispatch('system/setClickId', D.clickId)
                that.$store.dispatch('user/setInvitCodeNum', 1)
                if (that.$store?.getters.invitCode && (!that.$store?.getters.token) && that.$store?.getters.invitCodeNum === 1) {
                    that.$store.dispatch('user/setInvitCodeNum', 2)
                    that.$store.dispatch('user/changeLoginType', 1)
                    that.$store.dispatch('user/setIsShowLoginPopup', true)
                }
            })
        }
    }
}
</script>
<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
    overflow: hidden;
}

html[data-device-nodesktop="0"] {
    body {
        ::-webkit-scrollbar {
            display: none;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    .van-overlay {
        transform: translateZ(100px) !important;
    }
}

.icon-s-cuowu-guanbi {
    font-size: 0.35rem !important;
    color: #fff;
}

body {
    background-color: #111c09 !important;
    margin: 0 !important;
    overflow: hidden;

    ::-webkit-scrollbar {
        width: .08rem;
        height: .06rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: .5rem;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background-color: #666;
        // border: .05rem solid #0A0A0A;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
        border-radius: 5px;
        background-color: transparent;
    }

    .ant-input-affix-wrapper {
        width: 100%;
        height: 100%;
        border: none !important;
        background-color: transparent !important;
        color: #fff !important;

        &:hover {
            .ant-input:not(.ant-input-disabled) {
                background-color: transparent !important;
                border: none !important;
            }
        }

        .ant-input:hover {
            background-color: transparent !important;
            border: none !important;
        }

        .ant-input:focus {
            background-color: transparent !important;
            border: none !important;
            box-shadow: none;
        }

        .ant-input:active {
            background-color: transparent !important;
            border: none !important;
        }

        .ant-input {
            width: 100%;
            height: 100%;
            border: none;
            background-color: transparent !important;
            color: #fff !important;
        }

        .ant-input-suffix {
            right: 0 !important;

            .ant-input-clear-icon {
                font-size: .29rem;
                color: #666;
            }
        }
    }
}
</style>
