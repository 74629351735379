import { getToken } from '@/utils/auth'
import { webSocketUrl } from '@/common/http'
import store from '@/store'

let socket = null // 实例对象
let lockReconnect = false // 是否真正建立连接
const timeout = 30 * 1000 // 20秒一次心跳
let timeoutObj = null // 心跳倒计时
let serverTimeoutObj = null // 服务心跳倒计时
let timeoutnum = null // 断开 重连倒计时

const initWebSocket = async () => {
    if ('WebSocket' in window) {
        const uInfo = store.getters.userInfo
        const wsUrl = webSocketUrl + `?memberInfoId=${uInfo.id}&salesPersonId=${uInfo.salesPersonId}`
        socket = new WebSocket(wsUrl)
        socket.onerror = webSocketOnError
        socket.onmessage = webSocketOnMessage
        socket.onclose = closeWebsocket
        socket.onopen = openWebsocket
    } else {
        console.log('Your browser does not support websocket, please switch to Chrome or Firefox')
    }
}

// 建立连接
const openWebsocket = (e) => {
    const uInfo = store.getters.userInfo
    const token = getToken()
    if (token) {
        const data = {
            token: token,
            memberInfoId: uInfo.id,
            salesPersonId: uInfo.salesPersonId
        }
        socket.send(JSON.stringify(data))
    } else {
        socket.close()
    }
    start()
}

const start = () => {
    // 开启心跳
    timeoutObj && clearTimeout(timeoutObj)
    serverTimeoutObj && clearTimeout(serverTimeoutObj)
    timeoutObj = setTimeout(function () {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息
        if (socket.readyState === 1) {
            // 如果连接正常
            const uInfo = store.getters.userInfo
            const token = getToken()
            if (token) {
                const data = {
                    token: token,
                    memberInfoId: uInfo.id,
                    salesPersonId: uInfo.salesPersonId
                }
                socket.send(JSON.stringify(data))
            } else {
                socket.close()
            }
        } else {
            // 否则重连
            reconnect()
        }
        serverTimeoutObj = setTimeout(function () {
            // 超时关闭
            socket.close()
        }, timeout)
    }, timeout)
}

// 重新连接
const reconnect = () => {
    if (lockReconnect) {
        return
    }
    lockReconnect = true
    // 没连接上会一直重连，设置延迟避免请求过多
    timeoutnum && clearTimeout(timeoutnum)
    timeoutnum = setTimeout(function () {
        // 新连接
        initWebSocket()
        lockReconnect = false
    }, 10000)
}

// 重置心跳
const reset = () => {
    // 清除时间
    clearTimeout(timeoutObj)
    clearTimeout(serverTimeoutObj)
    // 重启心跳
    start()
}

const sendWebsocket = (e) => {
    // socket.send(`我发消息了`);
}

const webSocketOnError = (e) => {
    // initWebSocket()
    reconnect()
}

// 服务器返回的数据
const webSocketOnMessage = (e) => {
    // 判断是否登录
    if (getToken()) {
        // window自定义事件[下面有说明]
        window.dispatchEvent(
            new CustomEvent('onmessageWS', {
                detail: {
                    data: e?.data
                }
            })
        )
    }
    reset()
}

const closeWebsocket = (e) => {
    reconnect()
}

// 断开连接
const close = () => {
    // WebSocket对象也有发送和关闭的两个方法，只需要在自定义方法中分别调用send()和close()即可实现。
    if (socket) socket.close()
}
// 具体问题具体分析,把需要用到的方法暴露出去
export default { initWebSocket, sendWebsocket, webSocketOnMessage, close }
