<template>
    <van-popup v-model="show" round @click-overlay="close">
        <div class="History_pop">
            <div class="header">
                <div>{{ $t("winRankList.History") }}</div>
                <div class="close">
                    <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                </div>
            </div>
            <div class="History_tab">
                <div class="tab_item" :class="{ 'act': tabAct == item.id }" v-for="(item, index) in tabList"
                    :key="index" @click="changeTime(item.id)">
                    {{ item.name }}</div>
            </div>
            <div class="table">
                <div class="tHeader">
                    <div>{{ $t('winRankList.Rank') }}</div>
                    <div>{{ $t('winRankList.PhoneNo') }}</div>
                    <div>{{ $t('winRankList.TotalBets') }}</div>
                    <div>{{ $t('winRankList.Rewards') }}</div>
                </div>
                <div class="tBody" scroll-y>
                    <div v-if="list.length">
                        <div class="tr" v-for="(item, index) in list" :key="index">
                            <div>{{ item.rankNumber }}</div>
                            <div>{{ item.showName }}</div>
                            <div>{{ CurrencyType }} {{ item.grandTotalBetting }}</div>
                            <div>{{ CurrencyType }} {{ item.rewardsAmt }}</div>
                        </div>
                    </div>
                    <div v-else class="nodata">
                        <img src="@/assets/common/empty.png" mode="widthFix" />
                        <div class="empty">{{ $t("lang.empty") }}</div>
                    </div>
                </div>
            </div>
        </div>
    </van-popup>
</template>

<script>
import { getHistoryRankList } from '@/api/winRankList.js'
export default {
    props: {
        eventId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            show: false,
            tabAct: 0,
            tabList: [{
                id: 0,
                name: this.$t('winRankList.LastDay'),
                key: 'everyday'
            },
            {
                id: 1,
                name: this.$t('winRankList.LastWeek'),
                key: 'weekly'
            },
            {
                id: 2,
                name: this.$t('winRankList.LastMonth'),
                key: 'monthly'
            }
            ],
            pageNo: 0,
            hasMore: true,
            loading: false,
            list: []
        }
    },
    methods: {
        close() {
            this.show = false
        },
        open() {
            this.show = true
            this.getData()
        },
        getData() {
            const params = {
                eventId: this.eventId,
                rankingListType: this.tabList[this.tabAct].key
            }
            getHistoryRankList(params).then((res) => {
                if (res.code) return
                const D = res.result
                this.list = D
            })
        },
        changeTime(idx) {
            this.tabAct = idx
            this.getData()
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.History_pop {
    // padding: .31rem;
    box-sizing: border-box;
    width: 6.9rem;
    background: $bg-popup-color;
    border-radius: .2rem;
    margin: 0 auto;
    padding-bottom: .06rem;

    .header {
        display: flex;
        height: 0.8rem;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $bg-navbar-color;

        &>div:first-child {
            font-size: 20px;
            font-weight: bold;
            color: #fff;
        }

        .close {
            color: $font-iconfont-color;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                color: #ffffff;
                rotate: -180deg;
            }
        }
    }

    .History_tab {
        height: 1.1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tab_item {
            height: .7rem;
            width: 33.3333%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: .32rem;
            font-size: .26rem;
            color: $font-tab-color;

            &:not(:last-child) {
                border-right: .01rem solid $border-color;
            }

            &.act {
                color: $border-tab-act-color;
            }
        }
    }

    .table {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-radius: .1rem;
        padding-bottom: .2rem;

        .tHeader {
            color: #FFFFFF;
            font-size: .24rem;
            height: 1rem;
            display: flex;
            align-items: center;
            background-color: #911416;
            height: .8rem;
            // line-height: .8rem;
            position: relative;
            z-index: 1;
            border-radius: .16rem;

            &>div:first-child {
                width: 20%;
            }

            &>div {
                flex: 1;
                text-align: center;
            }
        }
    }

    .tBody {
        flex: 1;
        border-top: 0;
        margin: 0 auto;
        border-radius: 0 0 .3rem .3rem;
        width: 100%;
        max-height: 62vh;
        overflow: auto;
        // min-height: 240upx;

        .tr {
            display: flex;
            align-items: center;
            border-bottom: .01rem dashed rgba(255, 255, 255, 0.4);
            height: .8rem;

            &:nth-child(2n+1) {
                background: $bg-menu-color;
                border-radius: .12rem;
            }

            &>div:first-child {
                width: 20%;
            }

            &>div {
                flex: 1;
                color: #A9ACAB;
                font-size: 18upx;
                text-align: center;
                line-height: 24upx;
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:first-child) {
                    color: #FFC480;
                }
            }
        }

        .last-tips {
            line-height: .62rem;
            width: 100%;
            text-align: center;
            font-size: .22rem;
            color: #bbb;
        }

        .nodata {
            width: 100%;
            height: 6.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &>img {
                width: 4.26rem;
                height: 2.77rem;
            }

            .empty {
                margin-top: .41rem;
                text-align: center;
                color: #A9ACAB;
                font-size: .24rem;
            }
        }
    }
}
</style>
