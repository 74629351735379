<template>
    <div class="pop" v-if="show" :style="position">
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        position: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {}
    },
    methods: {}
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.pop {
    background: $bg-popup-color;
    border-radius: .24rem;
    width: 100%;
    padding: .2rem .2rem .2rem .6rem;
    text-align: left;
    box-shadow: 0 4px 20px #0006;

    ul {
        list-style: disc;
        margin-bottom: 0;

        li {
            color: $font-sub-color;
            font-size: .24rem;

            .num {
                color: #ffffff;
            }
        }
    }
}
</style>
